import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import CheckboxIcon from '../../../assets/icons/CheckboxIcon';
import './style.scss';

function UpCheckbox({
  name,
  value,
  label,
  loading,
  autoFocus,
  helpContent,
  errorContent,
  wrapperClass = '',
  labelClass = '',
  isDisabled = false,
  onBlur,
  onChange,
}) {
  const [focussed, setFocussed] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  function onInputChange() {
    if (!onChange || loading) {
      return;
    }

    onChange(!value);
  }

  function onInputFocus() {
    setFocussed(true);
  }

  function onInputBlur() {
    setFocussed(false);
    if (!onBlur) {
      return;
    }

    onBlur();
  }

  function getHelpErrorContent() {
    if (errorContent) {
      return <div className="error-content">{errorContent}</div>;
    }

    if (helpContent) {
      return <div className="help-content">{helpContent}</div>;
    }

    return null;
  }

  return (
    <div
      className={classNames(
        'up-input-checkbox',
        wrapperClass,
        isDisabled ? 'disabled' : '',
        {
          'up-error-input': errorContent,
        },
      )}>
      <div
        className="input-wrapper"
        onClick={!isDisabled ? onInputChange : () => void 0}>
        <span className={classNames('checkbox', focussed ? 'focussed' : '')}>
          <input
            name={name}
            value={value}
            checked={value}
            ref={inputRef}
            type="checkbox"
            onBlur={onInputBlur}
            onChange={onInputChange}
            onFocus={onInputFocus}
            disabled={isDisabled}
          />
          {value ? (
            <CheckboxIcon checked />
          ) : (
            <CheckboxIcon error={errorContent ? true : false} />
          )}
        </span>
        {label && (
          <label className={labelClass} htmlFor={name}>
            {label}
          </label>
        )}
      </div>
      {getHelpErrorContent()}
    </div>
  );
}

export default UpCheckbox;

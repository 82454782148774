import React from 'react';
import cx from 'classnames';
import UpButton from '../../components/Common/UpButton';
import QuestionIcon from '../../assets/icons/QuestionIcon';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import AnalyticsService from '../../services/analytics';

function ContactSupport({ topPadding = 'mt-8', screen }) {
  function getPrefilledLink() {
    const bizData = LocalStorageHelper.getBizData();
    const userDetails = LocalStorageHelper.getUserDetails();

    const loginCred = userDetails?.emailMobile || '';
    const companyName =
      bizData?.map(b => `${b?.biz_name} (${b?.biz_id})`)?.join(', ') || '';

    return `https://docs.google.com/forms/d/e/1FAIpQLSdvAVdphUPw0s93pOgdjVgarMEm8wrOhRvUlCDnoAdNkeNkiw/viewform?usp=pp_url&entry.1433034111=${loginCred}&entry.24550726=${companyName}`;
  }

  function onContactSupportClick() {
    // AnalyticsService.onHelpSupportLink('post-login', screen);

    window.open(getPrefilledLink(), '_blank');
  }

  return (
    <div className={cx('text-sm flex justify-center items-center', topPadding)}>
      <div className="text-up-clr-dark-grey mr-1 flex items-center">
        <QuestionIcon />
        <span className="ml-2">Facing a problem?</span>
      </div>
      <UpButton variant="link" onClick={onContactSupportClick}>
        Contact Support
      </UpButton>
    </div>
  );
}

export default ContactSupport;

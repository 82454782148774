import React, { forwardRef } from 'react';
import classNames from "classnames";
import * as Select from '@radix-ui/react-select';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import DownArrowIcon from '../../../assets/icons/DownArrowIcon';
import CheckMarkIcon from '../../../assets/icons/CheckMarkIcon';
import './style.scss';

function UpSelectDropdown({
  value,
  onValueChange,
  options = [],
  optionProps = {},
  triggerProps = {},
  withGrouping = false,
  placeholder = '',
  label,
  errorContent,
  wrapperClass = '',
  isDisabled = false,
}) {
  function renderOptions() {
    if (withGrouping) {
      return options?.map(grp => {
        return (
          <Select.Group key={grp.groupName}>
            <Select.Label className="select-label">
              {grp.groupName}
            </Select.Label>
            {grp.groupOptions?.map(o => {
              return (
                <UpSelectOption
                  key={o.value}
                  value={o.value}
                  label={o.label}
                  customLabel={o.customLabel}
                  {...optionProps}
                />
              );
            })}
          </Select.Group>
        );
      });
    }

    return options?.map(o => {
      return (
        <UpSelectOption
          key={o.value}
          value={o.value}
          label={o.label}
          customLabel={o.customLabel}
          {...optionProps}
        />
      );
    });
  }

  return (
    <div className={classNames("up-select-dropdown", errorContent ? "error" : wrapperClass)}>
      {label && <label>{label}</label>}
      <Select.Root value={value} onValueChange={onValueChange} disabled={isDisabled}>
        <Select.Trigger
          aria-label="select"
          className="select-trigger"
          {...triggerProps}>
          <Select.Value placeholder={placeholder} />
          <Select.Icon className="select-icon">
            <DownArrowIcon />
          </Select.Icon>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content className="up-select-content" position="popper">
            <ScrollArea.Root>
              <Select.Viewport asChild>
                <ScrollArea.Viewport>{renderOptions()}</ScrollArea.Viewport>
              </Select.Viewport>
            </ScrollArea.Root>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
      {errorContent && <div className="error-content">{errorContent}</div>}
    </div>
  );
}

const UpSelectOption = forwardRef(function UpSelectOption(
  { label, customLabel, ...props },
  forwardedRef,
) {
  return (
    <Select.Item className="select-item" {...props} ref={forwardedRef}>
      {customLabel || <Select.ItemText>{label}</Select.ItemText>}
      <Select.ItemIndicator className="select-item-indicator">
        <CheckMarkIcon />
      </Select.ItemIndicator>
    </Select.Item>
  );
});

export default UpSelectDropdown;

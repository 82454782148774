import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import './style.scss';

function UpPopover({
  content,
  children,
  contentWidth = '',
  arrowAlign = 'center',
  contentSide = 'bottom',
}) {
  return (
    <div className="inline-block">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span>{children}</span>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            sideOffset={8}
            avoidCollisions
            side={contentSide}
            align={arrowAlign}
            collisionPadding={20}
            style={{ width: contentWidth }}
            className="up-popover-content">
            <Popover.Arrow
              width={12}
              height={10}
              className="up-popover-arrow"
            />
            <div className="content-wrapper">{content}</div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}

export default UpPopover;

import React from 'react';
import { toast } from 'react-hot-toast';
import CloseIcon from '../../../assets/icons/CloseIcon';
import CheckCircleIcon from '../../../assets/icons/CheckCircleIcon';
import ErrorCircleIcon from '../../../assets/icons/ErrorCircleIcon';

function success(message) {
  toast(t => (
    <div className="flex items-center">
      <span>{<CheckCircleIcon />}</span>
      <span className="pl-2 pr-4">{message}</span>
      <button onClick={() => toast.dismiss(t.id)}>{<CloseIcon />}</button>
    </div>
  ));
}

function error(message) {
  toast(t => (
    <div className="flex items-center">
      <span>{<ErrorCircleIcon />}</span>
      <span className="pl-2 pr-4">{message}</span>
      <button onClick={() => toast.dismiss(t.id)}>{<CloseIcon />}</button>
    </div>
  ));
}

const UpToast = {
  success,
  error,
};

export default UpToast;

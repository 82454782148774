import UpToast from "../components/Common/UpToast/index.jsx";

const CaptchaHelper = {
  async getToken(captchaRef) {
    if (!(captchaRef || captchaRef.current)) {
      console.log('Captcha Ref null', captchaRef);
      UpToast.error("Could not evaluate captcha. E_NO_CPT_REF");
      return null;
    }

    try {
      return await captchaRef?.current?.executeAsync();
    } catch (e) {
      console.error('Could not evaluate captcha', e);
      UpToast.error("Could not evaluate captcha. Please make sure you are not using any Ad Blockers. E_CPT_EVAL");

      throw e;
    } finally {
      try {
        captchaRef?.current?.reset();
      } catch (e) {
        console.error('Could not reset', e);
      }
    }

    return null;
  }
};

export default CaptchaHelper;

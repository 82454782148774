import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CardHeader from '../../components/CardHeader';
import SetupCompleteImg from '../../assets/images/setup-complete.svg';
import UpButton from '../../components/Common/UpButton';

function CompleteInvite() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div className="light-view-wrapper">
      <CardHeader title="Account Setup Complete" />
      <div className="text-sm text-up-clr-dark-grey mt-3">
        You have successfully accepted your invite to UrbanPiper.
      </div>
      <div className="text-center mb-4">
        <div style={{ maxWidth: 330 }} className="mx-auto my-8">
          <img
            src={SetupCompleteImg}
            alt="Setup Complete"
            className="w-full h-full object-contain"
          />
        </div>
        <UpButton onClick={() => navigate(state?.redirect || '/business')}>
          Take me to UrbanPiper
        </UpButton>
      </div>
    </div>
  );
}

export default CompleteInvite;

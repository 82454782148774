import React from 'react';

function CallIcon(props) {
  return (
    <span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M17.7751 14.3767L16.8257 16.9901C16.5892 17.641 15.871 17.972 15.2198 17.7367C13.5838 17.1457 10.5017 15.7192 7.39134 12.6088C4.28103 9.49854 2.85415 6.41666 2.26321 4.78072C2.0279 4.12947 2.35884 3.41104 3.00978 3.17479L5.62321 2.22541C5.88196 2.13135 6.17134 2.2176 6.33665 2.43791L8.5704 5.41604C8.74603 5.65041 8.73571 5.97541 8.54509 6.1976L6.99634 8.00447C6.99634 8.00447 7.64353 9.32697 9.15853 10.842C10.6735 12.357 11.996 13.0042 11.996 13.0042L13.8029 11.4554C14.0254 11.2648 14.3504 11.2542 14.5845 11.4301L17.5626 13.6638C17.7826 13.8285 17.8688 14.1182 17.7751 14.3767Z"
          fill="#A1A5B2"
        />
      </svg>
    </span>
  );
}

export default CallIcon;

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import CallIcon from '../../assets/icons/CallIcon';
import MailIcon from '../../assets/icons/MailIcon';
import CardHeader from '../../components/CardHeader';
import UpButton from '../../components/Common/UpButton';
import UpInput from '../../components/Common/UpInput';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import CountryCodeSelect from '../../components/CountryCodeSelect';
import { checkErrorTypeString, isValidPassword } from '../../helpers/utils';
import { isValidPhoneNumberOrEmail } from '../../helpers/utils';
import { acceptInvite } from '../../api/auth';
import PathStatus from '../../constants/PathStatus';
import { getRedirectRoute } from '../../helpers/bizDataHelpers';
import UpToast from '../../components/Common/UpToast';
import ReCAPTCHA from 'react-google-recaptcha';
import { getRecaptchaSiteKey } from '../../helpers/environment.js';
import CaptchaHelper from '../../helpers/CaptchaHelper.js';
import { OMK_COUNTRIES } from '../../helpers/bizDataHelpers';
import UpCheckbox from '../../components/Common/UpCheckbox/index.jsx';

import './style.scss';

const DEFAULT_VALUE = {
  value: '',
  error: '',
};

function RegisterInvite() {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const { details } = LocalStorageHelper.getInviteData() || {};
  const registerForm = LocalStorageHelper.getRegisterFormData();
  const countryOrigin = window?.GEO_DATA?.country || 'IN';

  const [lastName, setLastName] = useState({
    ...DEFAULT_VALUE,
    value: registerForm?.last_name || '',
  });
  const [firstName, setFirstName] = useState({
    ...DEFAULT_VALUE,
    value: registerForm?.first_name || '',
  });
  const [email, setEmail] = useState({
    ...DEFAULT_VALUE,
    value: details?.phone ? registerForm?.email || '' : '',
  });
  const [mobile, setMobile] = useState({
    ...DEFAULT_VALUE,
    value: details?.email ? registerForm?.phone || '' : '',
  });
  const [consentCheck, setConsentCheck] = useState({
    ...DEFAULT_VALUE,
    value: false,
  });

  const [countryCode, setCountryCode] = useState(registerForm?.isd_code || '');

  const [newPassword, setNewPassword] = useState(DEFAULT_VALUE);
  const [confirmPassword, setConfirmPassword] = useState(DEFAULT_VALUE);

  const { isLoading, mutate: callAcceptInvite } = useMutation({
    mutationFn: ({ params }) => acceptInvite(params),
    onSuccess: response => {
      const pathStatusResponse = response?.path_status;

      if (response?.access_token) {
        LocalStorageHelper.setAccessToken(response?.access_token);
      }

      if (pathStatusResponse === PathStatus.OTP_VALIDATE_INVITE_USER) {
        const registerFormData = getEnteredUserData();

        LocalStorageHelper.setRegisterFormData({
          ...registerFormData,
          password1: '',
          password2: '',
        });

        navigate('/invite/auth', {
          state: {
            userData: registerFormData,
            otpSentOn: email?.value || mobile?.value,
          },
        });
        return;
      }

      const redirect = getRedirectRoute(response);

      if (redirect) {
        navigate(redirect);
      }
    },
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
    },
  });

  async function handleSubmit(e) {
    e.preventDefault();
    let formError = false;

    if (!firstName?.value) {
      formError = true;
      setFirstName({
        ...firstName,
        error: 'This field cannot be empty',
      });
    }

    if (!lastName?.value) {
      formError = true;
      setLastName({
        ...lastName,
        error: 'This field cannot be empty',
      });
    }

    if (email?.value && !isValidPhoneNumberOrEmail(email?.value)) {
      formError = true;
      setEmail({
        ...email,
        error: 'Enter valid email address',
      });
    }

    if (
      mobile?.value &&
      !isValidPhoneNumberOrEmail(mobile?.value, countryCode)
    ) {
      formError = true;
      setMobile({
        ...mobile,
        error: 'Enter valid mobile number',
      });
    }

    if (newPassword?.value !== confirmPassword?.value) {
      formError = true;
      setConfirmPassword({
        ...confirmPassword,
        error: 'Passwords do not match. Try again.',
      });
    }

    if (!isValidPassword(newPassword?.value)) {
      formError = true;
      setNewPassword({
        ...newPassword,
        error:
          'Password must contain 8 characters, 1 number and 1 special character.',
      });
    }

	if (showConsentCheckBox() && !consentCheck.value) {
      formError = true;
      setConsentCheck({
        ...consentCheck,
        error: 'You need to check the box to continue',
      });
    }

    if (formError) {
      return;
    }

    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    callAcceptInvite({
      params: { user_data: getEnteredUserData(), re_captcha: captchaToken },
    });
  }

  function onNewPasswordBlur() {
    if (!newPassword?.value) {
      return;
    }

    if (isValidPassword(newPassword?.value)) {
      setNewPassword({
        ...newPassword,
        error: '',
      });
      return;
    }

    setNewPassword({
      ...newPassword,
      error:
        'Password must contain 8 characters, 1 number and 1 special character.',
    });
  }

  function getEnteredUserData() {
    const userdata = {
      first_name: firstName?.value,
      last_name: lastName?.value,
      password1: newPassword?.value,
      password2: confirmPassword?.value,
    };

    if (mobile?.value || details?.phone) {
      userdata.phone = mobile?.value || details?.phone;
      userdata.isd_code = countryCode || details?.isdCode;
    }

    if (email?.value || details?.email) {
      userdata.email = email?.value || details?.email;
    }

    return userdata;
  }

  function showConsentCheckBox() {
    if (countryOrigin) {
      if (['usa', 'us'].includes(countryOrigin.toLowerCase())) {
        return true;
      }
    }
    return false;
  }
	 
  return (
    <div className="mb-8">
      <UpLoadingBar loading={isLoading} />
      <div
        className="light-view-wrapper relative"
        style={{ paddingLeft: 0, paddingRight: 0 }}>
        <UpLoadingCover loading={isLoading} />
        <div>
          <div className="px-4 md:px-10 border-b border-up-clr-neutral-grey mb-8">
            <CardHeader title="Complete Your Registration" />
            <div className="text-up-clr-dark-grey text-sm mt-5 mb-8">
              {details?.phone && details?.isdCode && (
                <div className="flex mb-2">
                  <CallIcon width="16" height="16" />
                  <div className="ml-3">{`${details?.isdCode} ${details?.phone}`}</div>
                </div>
              )}
              {details?.email && (
                <div className="flex">
                  <MailIcon className="mt-0.5" width="16" height="16" />
                  <div className="ml-3">{details?.email}</div>
                </div>
              )}
            </div>
          </div>
          <div className="px-4 md:px-10">
            <form onSubmit={handleSubmit}>
              <div className="block sm:flex gap-4 mb-4">
                <UpInput
                  autoFocus
                  type="text"
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  wrapperClass="flex-1 mb-4 sm:mb-0"
                  value={firstName?.value}
                  onChange={updated =>
                    setFirstName({
                      value: updated,
                      error: '',
                    })
                  }
                  errorContent={firstName?.error}
                />
                <UpInput
                  type="text"
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  wrapperClass="flex-1"
                  value={lastName?.value}
                  onChange={updated =>
                    setLastName({
                      value: updated,
                      error: '',
                    })
                  }
                  errorContent={lastName?.error}
                />
              </div>
              {!details?.phone && !OMK_COUNTRIES.includes(countryOrigin) && (
                <UpInput
                  type="text"
                  name="mobile"
                  wrapperClass="mb-4"
                  label="Mobile Number"
                  secondaryLabel="(optional)"
                  placeholder="XXX-XXX-XXXX"
                  showExtraInput
                  value={mobile?.value}
                  onChange={updated => {
                    setMobile({
                      value: updated,
                      error: '',
                    });
                  }}
                  extraInput={
                    <CountryCodeSelect
                      value={countryCode}
                      setCountryCode={setCountryCode}
                    />
                  }
                  errorContent={mobile?.error}
                />
              )}
              {!details?.email && (
                <UpInput
                  type="text"
                  name="email"
                  wrapperClass="mb-4"
                  label="Email Address"
                  secondaryLabel="(optional)"
                  placeholder="Email Address"
                  value={email?.value}
                  onChange={updated => {
                    setEmail({
                      value: updated,
                      error: '',
                    });
                  }}
                  errorContent={email?.error}
                />
              )}
              <UpInput
                type="password"
                name="new_password"
                label="Set Password"
                placeholder="New Password"
                helpContent="Password must contain 8 characters, 1 number and 1 special character."
                value={newPassword?.value}
                onBlur={onNewPasswordBlur}
                errorContent={newPassword?.error}
                onChange={updated => {
                  setNewPassword({
                    value: updated,
                    error: '',
                  });
                }}
              />
              <UpInput
                type="password"
                name="confirm_password"
                label="Confirm Password"
                wrapperClass="mt-4 mb-6"
                placeholder="Confirm Password"
                value={confirmPassword?.value}
                errorContent={confirmPassword?.error}
                onChange={updated => {
                  setConfirmPassword({
                    value: updated,
                    error: '',
                  });
                }}
              />

              {showConsentCheckBox() ? (
                <div className="register-invite-consent-checkbox">
                  <UpCheckbox
                    label={
                      <span className="legal mb-4 text-up-clr-dark-grey text-sm  select-none">
                        By checking this box, you consent to UrbanPiper to send
                        OTP-related SMS. Message Frequency may vary. Standard
                        message and data rates may apply. For HELP, please text
                        us at
                        <a
                          onClick={e => e.stopPropagation()} // to make sure clicking on the link doesn't check/uncheck the box.
                          href="tel:+1(833)673-3762">
                          &nbsp; +1(833)673-3762
                        </a>
                      </span>
                    }
                    labelClass="ml-2"
                    value={consentCheck.value}
                    wrapperClass="mt-2 mb-4"
                    onChange={isChecked => {
                      setConsentCheck({
                        value: isChecked,
                        error: '',
                      });
                    }}
                    errorContent={consentCheck?.error}
                  />
                </div>
              ) : null}

              <div className="legal mb-4 text-up-clr-dark-grey text-sm select-none">
                By clicking on continue, you agree to UrbanPiper’s
                <a
                  href="https://www.urbanpiper.com/legal/customer-terms-and-conditions"
                  target="_blank"
                  rel="noreferrer">
                  {' '}
                  Terms & Conditions
                </a>{' '}
                and
                <a
                  href="https://www.urbanpiper.com/legal/privacy-policy"
                  target="_blank"
                  rel="noreferrer">
                  {' '}
                  Privacy Policy
                </a>
              </div>
              <UpButton
                type="submit">
                Continue
              </UpButton>
            </form>
          </div>
        </div>
      </div>

      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        badge="bottomleft"
        sitekey={getRecaptchaSiteKey()}
      />
    </div>
  );
}

export default RegisterInvite;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { createNewPassword } from '../../api/auth';
import CardHeader from '../../components/CardHeader';
import UpInput from '../../components/Common/UpInput';
import UpToast from '../../components/Common/UpToast';
import UpButton from '../../components/Common/UpButton';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import { checkErrorTypeString, isValidPassword } from '../../helpers/utils';
import { useEffect } from 'react';
import AnalyticsService from '../../services/analytics';

function ResetPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const { selectedLogin, isMobile } = LocalStorageHelper.getUserDetails();

  const { isLoading, mutate: callCreatePassword } = useMutation({
    mutationFn: ({ loginSource, params }) =>
      createNewPassword(loginSource, params),
    onSuccess: resp => {
      LocalStorageHelper.setAccessToken('');
      UpToast.success(resp?.message);
      navigate('/login/password');
    },
    onError: err => {
      const errorMessage = checkErrorTypeString(err?.response?.data?.message);
      setConfirmPasswordError(errorMessage);
    },
  });

  useEffect(() => {
    // AnalyticsService.onUleResetPasswordPage();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match. Try again.');
      return;
    }

    if (!isValidPassword(newPassword)) {
      setNewPasswordError(
        'Password must contain 8 characters, 1 number and 1 special character.',
      );
      return;
    }

    if (
      !newPassword ||
      !confirmPassword ||
      newPasswordError ||
      confirmPasswordError
    ) {
      return;
    }

    callCreatePassword({
      loginSource: isMobile ? 'phone' : 'email',
      params: {
        password1: newPassword,
        password2: confirmPassword,
      },
    });
  }

  function onNewPasswordChange(updatedValue) {
    setNewPasswordError('');
    setNewPassword(updatedValue);
  }

  function onConfirmPasswordChange(updatedValue) {
    setConfirmPasswordError('');
    setConfirmPassword(updatedValue);
  }

  function onNewPasswordBlur() {
    if (!newPassword) {
      return;
    }

    if (isValidPassword(newPassword)) {
      setNewPasswordError('');
      return;
    }

    setNewPasswordError(
      'Password must contain 8 characters, 1 number and 1 special character.',
    );
  }

  return (
    <div>
      <UpLoadingBar loading={isLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={isLoading} />
        <div>
          <CardHeader title="Reset Your Password" />
          <div className="text-sm text-up-clr-dark-grey mt-3">
            Reset password for all accounts linked to{' '}
            <span className="font-semibold">{selectedLogin}</span>
          </div>
          <form onSubmit={handleSubmit}>
            <UpInput
              autoFocus
              loading={isLoading}
              type="password"
              wrapperClass="mt-8"
              name="new_password"
              label="New Password"
              placeholder="New Password"
              helpContent="Password must contain 8 characters, 1 number and 1 special character."
              value={newPassword}
              onBlur={onNewPasswordBlur}
              onChange={onNewPasswordChange}
              errorContent={newPasswordError}
            />
            <UpInput
              loading={isLoading}
              type="password"
              name="confirm_password"
              label="Confirm Password"
              placeholder="Confirm Password"
              wrapperClass="mt-4 mb-8"
              value={confirmPassword}
              onChange={onConfirmPasswordChange}
              errorContent={confirmPasswordError}
            />
            <UpButton type="submit" loading={isLoading}>
              Reset Password
            </UpButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

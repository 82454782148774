import React from 'react';

function OtherApps(props) {
  return (
    <span>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12 2.25C7.95182 2.25 4.47293 4.72977 3.00073 8.25H3V8.25293C2.5177 9.40717 2.25 10.6729 2.25 12C2.25 13.3271 2.5177 14.5928 3 15.7471V15.75H3.00073C4.47293 19.2702 7.95182 21.75 12 21.75C16.0482 21.75 19.5271 19.2702 20.9993 15.75H21V15.7471C21.4823 14.5928 21.75 13.3271 21.75 12C21.75 10.6729 21.4823 9.40717 21 8.25293V8.25H20.9993C19.5271 4.72977 16.0482 2.25 12 2.25ZM12 3.75C13.1411 3.75 14.372 5.48697 14.9832 8.25H9.01685C9.62803 5.48697 10.8589 3.75 12 3.75ZM8.90771 4.35352C8.27409 5.36522 7.78068 6.69675 7.4751 8.25H4.65454C5.55504 6.49336 7.06676 5.10074 8.90771 4.35352ZM15.0923 4.35352C16.9332 5.10074 18.445 6.49336 19.3455 8.25H16.5249C16.2193 6.69675 15.7259 5.36522 15.0923 4.35352ZM4.06421 9.75H7.24878C7.16997 10.469 7.125 11.2192 7.125 12C7.125 12.7808 7.16997 13.531 7.24878 14.25H4.06421C3.86098 13.5342 3.75 12.78 3.75 12C3.75 11.22 3.86098 10.4658 4.06421 9.75ZM8.7627 9.75H15.2373C15.3236 10.4569 15.375 11.2062 15.375 12C15.375 12.7938 15.3236 13.5431 15.2373 14.25H8.7627C8.67641 13.5431 8.625 12.7938 8.625 12C8.625 11.2062 8.67641 10.4569 8.7627 9.75ZM16.7512 9.75H19.9358C20.139 10.4658 20.25 11.22 20.25 12C20.25 12.78 20.139 13.5342 19.9358 14.25H16.7512C16.83 13.531 16.875 12.7808 16.875 12C16.875 11.2192 16.83 10.469 16.7512 9.75ZM4.65454 15.75H7.4751C7.78068 17.3033 8.27409 18.6348 8.90771 19.6465C7.06676 18.8993 5.55504 17.5066 4.65454 15.75ZM9.01685 15.75H14.9832C14.372 18.513 13.1411 20.25 12 20.25C10.8589 20.25 9.62803 18.513 9.01685 15.75ZM16.5249 15.75H19.3455C18.445 17.5066 16.9332 18.8993 15.0923 19.6465C15.7259 18.6348 16.2193 17.3033 16.5249 15.75Z" fill="#646464"/>
      </svg>
    </span>
  );
}

export default OtherApps;

import React from 'react';
import UpLoadingBar from '../Common/UpLoadingBar';
import UpLoadingCover from '../Common/UpLoadingCover';
import CardHeader from '../CardHeader';

function LoadingCard({ text }) {
  return (
    <div>
      <UpLoadingBar loading />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading />
        <div style={{ minHeight: '30vh' }}>
          {text && <CardHeader title={text} />}
        </div>
      </div>
    </div>
  );
}

export default LoadingCard;

import React from 'react';

function EyeCloseIcon(props) {
  return (
    <span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M4.20703 2.79297L2.79297 4.20703L5.07617 6.49023C2.19934 8.8382 1 12 1 12C1 12 4 20 12 20C14.0756 20 15.806 19.4547 17.2422 18.6562L19.793 21.207L21.207 19.793L4.20703 2.79297ZM12 4C10.788 4 9.7058 4.19972 8.7168 4.51172L11.2773 7.07227C11.5143 7.03727 11.753 7 12 7C14.761 7 17 9.239 17 12C17 12.247 16.9627 12.4857 16.9277 12.7227L20.3574 16.1523C22.2044 14.1023 23 12 23 12C23 12 20 4 12 4ZM7.83398 9.24805L9.29688 10.7109C9.10816 11.1008 9 11.5366 9 12C9 13.657 10.343 15 12 15C12.4634 15 12.8992 14.8918 13.2891 14.7031L14.752 16.166C13.962 16.6898 13.0193 17 12 17C9.239 17 7 14.761 7 12C7 10.9807 7.31024 10.038 7.83398 9.24805Z"
          fill="#A1A5B2"
        />
      </svg>
    </span>
  );
}

export default EyeCloseIcon;

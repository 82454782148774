import React, { useCallback } from 'react';
import classNames from "classnames";
import Select, { components } from 'react-select';
import DownArrowIcon from '../../../assets/icons/DownArrowIcon';
import CheckMarkIcon from '../../../assets/icons/CheckMarkIcon';
import "./style.scss"

function UpSelect({
  label,
  name,
  options = [],
  value = [],
  onValueChange,
  wrapperClass = '',
  labelKey = 'label',
  valueKey = 'value',
  isLoading = false,
  isSearchable = true,
  isClearable = true,
  isAsync = false,
  handleSearch,
  placeholder = 'Select',
  noOptionsMessage = 'No results found',
  errorContent,
  helpContent,
  isDisabled = false,
}) {
  const handleSearchInput = useCallback(
		(inputValue) => {
			if (isAsync) {
				handleSearch(inputValue);
			}
		},
		[isAsync, handleSearch]
	);

  function CustomOptionContainer({ children, ...props }) {
    return (
			<components.Option className="option" {...props}>
				<span>{children}</span>
        {
          props.isSelected &&
          <CheckMarkIcon />
        }
			</components.Option>
		);
  }

  function CustomDropdownIndicator({ children, ...props }) {
    return (
      <components.DropdownIndicator {...props}>
        <DownArrowIcon />
      </components.DropdownIndicator>
    );
  }

  function getHelpErrorContent() {
    if (errorContent) {
      return <div className="error-content">{errorContent}</div>;
    }

    if (helpContent) {
      return <div className="help-content">{helpContent}</div>;
    }

    return null;
  }

  return (
    <div className={classNames("up-select", errorContent ? "error" : "", wrapperClass)}>
      {label && <label className="label">{label}</label>}
      <Select
        options={options}
        name={name}
        value={value}
        onChange={(val) => onValueChange(val)}
        isLoading={isLoading}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        labelKey={labelKey}
        valueKey={valueKey}
        getOptionLabel={(option) => option[labelKey]}
        getOptionValue={(option) => option[valueKey]}
        onInputChange={handleSearchInput}
        classNamePrefix="react-select"
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        components={{ 
          Option: CustomOptionContainer,
          DropdownIndicator: CustomDropdownIndicator
        }}
      />
      {getHelpErrorContent()}
    </div>
  );
}
export default UpSelect;

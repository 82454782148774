import React from 'react';

function CheckMarkIcon(props) {
  return (
    <span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M13.5286 3.52881L6 11.0575L3.13802 8.19548L2.19531 9.13818L6 12.9429L14.4714 4.47152L13.5286 3.52881Z"
          fill="#2F58F2"
        />
      </svg>
    </span>
  );
}

export default CheckMarkIcon;

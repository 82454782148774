import React from 'react';

function RightArrowIcon(props) {
  return (
    <span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M6.66602 3.95312L5.66602 4.95312L8.71289 8L5.66602 11.0469L6.66602 12.0469L10.7129 8L6.66602 3.95312Z"
          fill="#979797"
        />
      </svg>
    </span>
  );
}

export default RightArrowIcon;

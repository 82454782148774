import React from 'react';

function MagicWandIcon(props) {
  return (
    <span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M3.30566 1.5L3.21338 2.14355C3.15263 2.56855 2.81855 2.90263 2.39355 2.96338L1.75 3.05566V3.44434L2.39355 3.53662C2.81855 3.59737 3.15263 3.93145 3.21338 4.35645L3.30566 5H3.69434L3.78662 4.35645C3.84737 3.93145 4.18145 3.59737 4.60645 3.53662L5.25 3.44434V3.05566L4.60645 2.96338C4.18145 2.90263 3.84737 2.56855 3.78662 2.14355L3.69434 1.5H3.30566ZM7.75 1.5L7.69092 1.91357C7.65192 2.18682 7.43731 2.40192 7.16406 2.44092L6.75 2.5V2.75L7.16357 2.80908C7.43682 2.84808 7.65192 3.06269 7.69092 3.33594L7.75 3.75H8L8.05908 3.33643C8.09808 3.06318 8.31269 2.84808 8.58594 2.80908L9 2.75V2.5L8.58643 2.44092C8.31318 2.40192 8.09808 2.18731 8.05908 1.91406L8 1.5H7.75ZM12.1694 2.00098C12.0808 2.00515 12.0103 2.0324 11.9609 2.08154C11.9606 2.08187 11.9603 2.08219 11.96 2.08252L2.04297 11.9995C1.65247 12.39 1.65247 13.0236 2.04297 13.4141L2.83594 14.207C3.22644 14.5978 3.8595 14.5978 4.25 14.207L14.167 4.29004C14.1673 4.28971 14.1676 4.28939 14.168 4.28906C14.2697 4.18725 14.2763 3.99683 14.1866 3.75141C14.0969 3.50599 13.9167 3.22134 13.6776 2.94769C13.4386 2.67405 13.1562 2.429 12.8803 2.25578C12.6043 2.08257 12.3526 1.99232 12.1694 2.00098ZM12.2651 3.19189C12.4822 3.48553 12.7649 3.76793 13.0586 3.98438L9.54297 7.5L8.74951 6.70703L12.2651 3.19189ZM12 9.75L11.8818 10.5776C11.8038 11.1241 11.3741 11.5538 10.8276 11.6318L10 11.75V12.25L10.8276 12.3682C11.3741 12.4462 11.8038 12.8759 11.8818 13.4224L12 14.25H12.5L12.6182 13.4224C12.6962 12.8759 13.1259 12.4462 13.6724 12.3682L14.5 12.25V11.75L13.6724 11.6318C13.1259 11.5538 12.6962 11.1241 12.6182 10.5776L12.5 9.75H12Z"
          fill="#222222"
        />
      </svg>
    </span>
  );
}

export default MagicWandIcon;

import React from 'react';
import './style.scss';

function CardHeader({ title, subtitle, wrapperClass = '' }) {
  return (
    <div className={`card-header ${wrapperClass}`}>
      <div className="header-title">{title}</div>
      {subtitle && (
        <div className="text-sm mt-2 text-up-clr-dark-grey font-semibold">
          {subtitle}
        </div>
      )}
    </div>
  );
}

export default CardHeader;

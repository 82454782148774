import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import HelpText from './HelpText';
import CardHeader from '../../components/CardHeader';
import UpInput from '../../components/Common/UpInput';
import UpButton from '../../components/Common/UpButton';
import { isValidPhoneNumberOrEmail } from '../../helpers/utils';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import CountryCodeSelect from '../../components/CountryCodeSelect';
import AnalyticsService from '../../services/analytics';
import { getBaseUrlPrime } from '../../helpers/environment';
import UpPopover from '../../components/Common/UpPopover/index.jsx';

function EmailMobileView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [countryCode, setCountryCode] = useState(
    searchParams.get('isdCode') || '',
  );
  const [emailMobile, setEmailMobile] = useState(
    searchParams.get('email') || searchParams.get('mobile') || '',
  );
  const redirectApp = LocalStorageHelper.getRedirectApp();
  const [emailMobileError, setEmailMobileError] = useState('');
  const isInputANumber = emailMobile?.length >= 2 && !isNaN(emailMobile);
  const isdCodeGeo = LocalStorageHelper.getIsdCodeGeo();

  const hideLoginFallback = LocalStorageHelper.getUserFeatureGroup() <= 100;
  const hideAppLoginFallback = LocalStorageHelper.getUserFeatureGroup() <= 100;

  useEffect(() => {
    const loginApp = searchParams.get('app');
    const isEdcDevice = searchParams.get('isEDC');
    const redirectApp = searchParams.get('redirect');

    if (!LocalStorageHelper.getAppLogin()) {
      LocalStorageHelper.setAppLogin(loginApp);
    }

    if (redirectApp) {
      LocalStorageHelper.setRedirectApp(redirectApp);
    }

    if (isEdcDevice) {
      LocalStorageHelper.setEdcDevice(Number(isEdcDevice));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // run this effect only if countryCode changes
    onInputBlur();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  function handleSubmit(e) {
    e.preventDefault();

    if (!isValidPhoneNumberOrEmail(emailMobile, countryCode)) {
      setEmailMobileError('Please enter valid email or mobile number');
      return;
    }

    if (emailMobileError) {
      return;
    }

    LocalStorageHelper.setUserDetails({
      countryCode,
      emailMobile,
      isMobile: isInputANumber,
      selectedLogin: isInputANumber
        ? `${countryCode}${emailMobile}`
        : emailMobile,
    });

    navigate('/login/password');
  }

  function onInputBlur() {
    if (!emailMobile) {
      return;
    }

    if (isValidPhoneNumberOrEmail(emailMobile, countryCode)) {
      setEmailMobileError('');
      return;
    }

    setEmailMobileError('Please enter valid email or mobile number');
  }

  function onInputChange(updatedValue) {
    setEmailMobileError('');
    setEmailMobile(updatedValue);
  }

  function onHelpVideoClick() {
    // AnalyticsService.onUleHelpVideo(redirectApp || 'N.A');

    window.open('https://youtu.be/5VSexAyg3wI', '_blank');
  }

  return (
    <div>
      <div className="h-2" />
      <div className="dark-view-wrapper">
        <div className="flex-grow mb-4">
          <div className="flex flex-row justify-between">
            <CardHeader title="Login" />
            {redirectApp === 'app' && (
              <UpPopover
                arrowAlign="start"
                contentSide="right"
                content={
                  <div className="py-2">
                    <UpButton
                      variant="link"
                      target="_self"
                      wrapperClass="w-max mx-auto"
                      href={`/login/old-account`}>
                      Login to old account
                    </UpButton>
                  </div>
                }>
                ...
              </UpPopover>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <UpInput
              autoFocus
              type="text"
              name="email_mobile"
              wrapperClass="mt-12 mb-8"
              label="Email/Mobile Number"
              placeholder={`Ex: hello@example.com or ${isdCodeGeo} 999-999-9999`}
              value={emailMobile}
              onBlur={onInputBlur}
              onChange={onInputChange}
              errorContent={emailMobileError}
              showExtraInput={isInputANumber}
              extraInput={
                <CountryCodeSelect
                  value={countryCode}
                  setCountryCode={setCountryCode}
                />
              }
            />
            <UpButton
              type="submit"
              wrapperClass={redirectApp === 'atlas' ? 'mb-5' : ''}>
              Continue
            </UpButton>
          </form>
          {redirectApp && redirectApp !== 'atlas' && (
            <p className="text-up-clr-light-grey text-center mt-4 mb-2">or</p>
          )}
          {redirectApp === 'atlas' && (
            <UpButton
              variant="link"
              onClick={onHelpVideoClick}
              wrapperClass="w-max mx-auto">
              Watch this help video
            </UpButton>
          )}
          {redirectApp === 'prime-web' &&
            (hideLoginFallback ? (
              <UpButton
                variant="link"
                target="_self"
                wrapperClass="w-max mx-auto"
                href={`/login/prime`}>
                Login with Prime Credentials
              </UpButton>
            ) : (
              <UpButton
                variant="link"
                target="_self"
                wrapperClass="w-max mx-auto"
                href={`${getBaseUrlPrime()}/auth/login/?reset_ule=42`}>
                Take me back to old Prime login
              </UpButton>
            ))}
          {redirectApp === 'app' &&
            (hideAppLoginFallback ? (
              <UpButton
                variant="link"
                target="_self"
                wrapperClass="w-max mx-auto"
                href={`/login/prime`}>
                Login with Prime Credentials
              </UpButton>
            ) : (
              <UpButton
                variant="link"
                target="_self"
                wrapperClass="w-max mx-auto"
                href={`/cancel`}>
                Take me back to old Prime login
              </UpButton>
            ))}
        </div>
        <HelpText screen="landing" prefilled={{ loginCred: emailMobile }} />
      </div>
    </div>
  );
}

export default EmailMobileView;

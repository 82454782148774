import React from 'react';
import './style.scss';

function UpButton({
  icon,
  href,
  variant,
  onClick,
  loading,
  disabled = false,
  children,
  type = 'button',
  target = '_blank',
  isSecondary = false,
  wrapperClass = '',
}) {
  function handleButtonClick() {
    if (!onClick || loading) {
      return;
    }

    onClick();
  }

  if (variant === 'link') {
    return (
      <div className={`up-link-button text-sm ${wrapperClass}`}>
        {href ? (
          <a href={href} target={target}>
            <ButtonContent content={children} icon={icon} />
          </a>
        ) : (
          <button disabled={disabled} type={type} onClick={handleButtonClick}>
            <ButtonContent content={children} icon={icon} />
          </button>
        )}
      </div>
    );
  }

  if (isSecondary) {
    return (
      <div className={`up-secondary-button ${wrapperClass}`}>
        <button disabled={disabled} type={type} onClick={handleButtonClick}>
          <ButtonContent content={children} icon={icon} />
        </button>
      </div>
    );
  }

  return (
    <div className={`up-primary-button ${wrapperClass}`}>
      <button disabled={disabled} type={type} onClick={handleButtonClick}>
        <ButtonContent content={children} icon={icon} />
      </button>
    </div>
  );
}

function ButtonContent({ content, icon }) {
  const IconComponent = icon;

  return (
    <>
      {icon && <IconComponent className="mr-2" />}
      <span>{content}</span>
    </>
  );
}

export default UpButton;

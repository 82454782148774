import React from 'react';

function CheckboxIcon({ checked = false, error = null, ...props }) {
  if (checked) {
    return (
      <span className="checkbox-icon checked">
        <svg
          width="18"
          height="18"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}>
          <path
            d="M15.5 0.5H0.5V15.5H15.5V0.5ZM6.33333 12.5117L2.41083 8.58917L3.58917 7.41083L6.33333 10.155L12.4108 4.0775L13.5892 5.25583L6.33333 12.5117Z"
            fill="#2F58F2"
          />
        </svg>
      </span>
    );
  }
  return (
    <span className="checkbox-icon">
      <svg
        width="18"
        height="18"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M1.5 14.5V1.5H14.5V14.5H1.5Z"
          stroke={error ? '#FF425C' : '#D0D0D0'}
          strokeWidth={error ? '1' : '2'}
        />
      </svg>
    </span>
  );
}

export default CheckboxIcon;

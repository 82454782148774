import React from 'react';
import { useNavigate } from 'react-router-dom';
import CardHeader from '../../components/CardHeader';
import UrbanWomenImg from '../../assets/images/urban-woman.svg';
import UpButton from '../../components/Common/UpButton';

function PrimeAccessRequired() {
  const navigate = useNavigate();

  return (
    <div className="light-view-wrapper">
      <CardHeader title="Prime Access Required" />
      <div>
        <div style={{ maxWidth: 208 }} className="mx-auto text-center my-5">
          <img
            src={UrbanWomenImg}
            alt="Prime Access Required"
            className="w-full h-full object-contain"
          />
        </div>
        <div className="text-sm text-up-clr-dark-grey mb-12">
          <p className="mb-4">
            You need a prime account to login to this app. Try one of the
            following :
          </p>
          <ul className="list-disc pl-5">
            <li className="mb-2">
              Request an invite from your admin for Prime Access.
            </li>
            <li className="mb-2">Try logging in with another account.</li>
            <li className="mb-2">
              If you want to access Atlas, try logging into your account through
              a web browser.
            </li>
          </ul>
        </div>
        <UpButton onClick={() => navigate('/logout')}>Switch Account</UpButton>
      </div>
    </div>
  );
}

export default PrimeAccessRequired;

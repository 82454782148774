import React, {useEffect, useState} from 'react';
import UpLoadingBar from "../../components/Common/UpLoadingBar/index.jsx";
import UpLoadingCover from "../../components/Common/UpLoadingCover/index.jsx";
import CardHeader from "../../components/CardHeader/index.jsx";
import UpButton from "../../components/Common/UpButton/index.jsx";
import UpInput from "../../components/Common/UpInput/index.jsx";
import {useNavigate, useSearchParams} from "react-router-dom";
import AnalyticsService from "../../services/analytics.js";
import {getBaseUrlAtlas, getBaseUrlPrime} from "../../helpers/environment.js";
import LocalStorageHelper from "../../helpers/localStorageHelpers.js";

const ALLOWED_CODES = ["upipr"]

export default function OldLogin() {
  const [searchParams] = useSearchParams();

  let app = searchParams.get('app')
    || LocalStorageHelper.getRedirectApp()
    || 'prime-web';

  const [bizId, setBizId] = useState(
    searchParams.get('code')
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onBizId(value) {
    setBizId(value);
    setError(null);
  }

  useEffect(() => {
    if (bizId) {
      // if biz id is there on load, it was passed in through URL
      handleSubmit();
    }
  }, []);

  function handleSubmit(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (!bizId) {
      setError('Please enter valid Login ID');
      return;
    }

    if (!ALLOWED_CODES.includes(bizId)) {
      setError("Invalid code. Please contact support");
      return;
    }

    setLoading(true);
    // AnalyticsService.onOldLoginFlow(app, bizId);

    // add a delay to ensure we get the mixpanel event
    setTimeout(() => {
      if (app === 'atlas') {
        window.location.href = `${getBaseUrlAtlas()}/login?reset_ule=42`;
      } else if (app === 'app') {
        // mobile apps
        window.location.href = '/cancel';
      } else {
        // navigate to absolute prime web url
        window.location.href = `${getBaseUrlPrime()}/auth/login/?reset_ule=42`;
      }
    }, 500);
  }

  return (<div>
    <UpLoadingBar loading={loading}/>
    <div className="dark-view-wrapper relative">
      <UpLoadingCover loading={loading}/>
      <div className="flex-grow">
        <CardHeader
          title="Login with old account"
        />
        <form onSubmit={handleSubmit}>
          <UpInput
            autoFocus
            loading={loading}
            name="code"
            label="Enter your passcode"
            wrapperClass="mt-12 mb-8"
            helpContent="Don't have a passcode? Please contact support"
            errorContent={error}
            value={bizId}
            onChange={onBizId}
          />
          <UpButton type="submit" wrapperClass="mb-5" loading={loading}>
            Login
          </UpButton>
        </form>
      </div>
    </div>
  </div>);
}

const PathStatus = {
  LOGIN: 'LOGIN',
  PENDING_INVITES: 'PENDING_INVITES',
  APP_BIZ_SELECTION: 'APP_BIZ_SELECTION',
  UPDATE_UNIQUE_DATA: 'UPDATE_UNIQUE_DATA',
  CREATE_NEW_PASSWORD: 'CREATE_NEW_PASSWORD',
  OTP_VALIDATE_INVITE_USER: 'OTP_VALIDATE_INVITE_USER',
};

export default PathStatus;

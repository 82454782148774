import React from 'react';

function CheckCircleIcon(props) {
  return (
    <span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M10 1.875C5.51281 1.875 1.875 5.51281 1.875 10C1.875 14.4872 5.51281 18.125 10 18.125C14.4872 18.125 18.125 14.4872 18.125 10C18.125 5.51281 14.4872 1.875 10 1.875ZM9.08781 13.3588L5.835 10.4672L6.66531 9.53281L9.0375 11.6412L13.75 6.5625L14.8169 7.62937L9.08781 13.3588Z"
          fill="#2ECC71"
        />
      </svg>
    </span>
  );
}

export default CheckCircleIcon;

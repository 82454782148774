import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import RadioIcon from '../../../assets/icons/RadioIcon';
import './style.scss';

function UpRadio({
  name,
  value,
  label,
  loading,
  autoFocus,
  helpContent,
  errorContent,
  wrapperClass = '',
  onBlur,
  onChange,
}) {
  const [focussed, setFocussed] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  function onInputChange(e) {
    if (!onChange || loading) {
      return;
    }

    onChange(!value);
  }

  function onInputFocus() {
    setFocussed(true);
  }

  function onInputBlur() {
    setFocussed(false);
    if (!onBlur) {
      return;
    }

    onBlur();
  }

  function getHelpErrorContent() {
    if (errorContent) {
      return <div className="error-content">{errorContent}</div>;
    }

    if (helpContent) {
      return <div className="help-content">{helpContent}</div>;
    }

    return null;
  }

  return (
    <div
      className={classNames('up-input-radio', wrapperClass, {
        'up-error-input': errorContent,
      })}>
      <div className="input-wrapper" onClick={onInputChange}>
        <span className={classNames("radio", focussed ? "focussed" : "")}>
          <input
            name={name}
            value={value}
            checked={value}
            ref={inputRef}
            type="radio"
            onBlur={onInputBlur}
            onChange={onInputChange}
            onFocus={onInputFocus}
          />
          {value ? <RadioIcon selected /> : <RadioIcon />}
        </span>
        {label && <label htmlFor={name}>{label}</label>}
      </div>
      {getHelpErrorContent()}
    </div>
  );
}

export default UpRadio;

import React from 'react';

function MailIcon(props) {
  return (
    <span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M1.5625 3.4375V4.74976L10 10.647L18.4375 4.75342V3.4375H1.5625ZM1.5625 6.00647V16.5625H18.4375V7.10632L10 11.875L1.5625 6.00647Z"
          fill="#A1A5B2"
        />
      </svg>
    </span>
  );
}

export default MailIcon;

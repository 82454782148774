/**
 * Get mixpanel token
 */
export function getMixPanelToken() {
  return import.meta.env.ULE_BUILD_ENV === 'production'
    ? '0c3b5ee26b9d2e52e50d3d3ba2140978'
    : '0c3b5ee26b9d2e52e50d3d3ba2140978';
}

/**
 * Get posthog key
 */
export function getPostHogKey() {
  return import.meta.env.ULE_BUILD_ENV === 'production'
    ? 'phc_dqhatovy3EwLg2qK00LKcoTKS1RTUMYYBRJK6dgS60y'
    : 'phc_dqhatovy3EwLg2qK00LKcoTKS1RTUMYYBRJK6dgS60y';
}

/**
 * Get posthog host
 */
export function getPostHogHost() {
  return import.meta.env.ULE_BUILD_ENV === 'production'
    ? 'https://us.posthog.com'
    : 'https://us.posthog.com';
}

/**
 * Get recaptcha site key
 */
export function getRecaptchaSiteKey() {
  return import.meta.env.ULE_BUILD_ENV === 'production'
    ? '6LejhEEnAAAAAOlGPXLj3JEl0sPJsLq4fBn3EMeI'
    : '6LejhEEnAAAAAOlGPXLj3JEl0sPJsLq4fBn3EMeI';
}

/**
 * Get base URL for Prime
 */
export function getBaseUrlPrime() {
  return import.meta.env.ULE_BUILD_ENV === 'production'
    ? 'https://prime.urbanpiper.com'
    : 'https://stl-prime-b1.urbanpiper.com';
}

/**
 * Get base URL for Atlas
 */
export function getBaseUrlAtlas() {
  return import.meta.env.ULE_BUILD_ENV === 'production'
    ? 'https://atlas.urbanpiper.com'
    : 'https://test-atlas.urbanpiper.com';
}

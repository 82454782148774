import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { WEB_VERSION } from '../../version';

function PageNotFound() {
  const error = useRouteError();

  useEffect(() => {
    if (error?.name && error?.message) {
      Sentry.captureException(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center p-6 bg-up-clr-lightest-grey">
      <div className="mr-10">
        <img
          alt="page-not-found"
          src="https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg"
        />
        <div className="text-center mt-10 ml-5">{WEB_VERSION}</div>
      </div>
      {error?.status && error?.data ? (
        <div>
          <div className="text-8xl font-bold mb-5 text-up-clr-light-grey">
            {error?.status}
          </div>
          <div>{error?.data}</div>
        </div>
      ) : (
        <div>
          <div className="text-up-clr-semantic-red text-lg font-bold">
            {error?.name}
          </div>
          <div className="text-up-clr-dark-grey text-sm mt-1 mb-2">
            Something has gone wrong. If the issue persists, please notify our
            support team.
          </div>
          <div className="text-sm">Ref : {error?.message || '-'}</div>
        </div>
      )}
    </div>
  );
}

export default PageNotFound;

import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ItemText } from '@radix-ui/react-select';
import UpSelectDropdown from '../Common/UpSelectDropdown';
import { getCountryCodes } from '../../api/auth';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import PlaceholderFlag from '../../assets/images/placeholder-flag.svg';

function CountryCodeSelect({ 
  value, 
  countryCode = true, 
  countryName = false, 
  setCountryCode, 
  isDisabled = false,
}) {
  const [countryCodeDetails, setCountryCodeDetails] = useState(null);

  const { isLoading, data: countryCodeData } = useQuery({
    queryKey: ['getCountryCodes'],
    queryFn: () =>
      getCountryCodes({ default_country_code: window?.GEO_DATA?.country }),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: transformApiResp,
  });

  if (isLoading || !countryCodeData?.data || !countryCodeDetails) {
    return (
      <div
        style={{ width: 120, height: 54 }}
        className="border rounded-sm border-up-clr-neutral-grey"
      />
    );
  }

  function transformApiResp(resp) {
    let defaultValue = !value ? (countryCode ? '+91' : 'India') : value;
    const apiData = resp?.data?.countries;
    const defaultStructure = [
      { groupName: 'Popular Countries', groupOptions: [] },
      { groupName: 'All Countries', groupOptions: [] },
    ];

    Object.keys(apiData || {})?.forEach(grp => {
      if (grp === 'popular_countries') {
        defaultStructure[0].groupOptions = apiData?.[grp]?.map(c => {
          if (c?.default) {
            defaultValue = countryCode ? c?.code : c?.name;
          }

          return {
            ...c,
            value: countryCode ? c.code : c?.name,
            customLabel: (
              <CountryCodeOption 
                details={c} 
                countryCode={countryCode} 
                countryName={countryName} 
              />
            ),
          };
        });
      }
      if (grp === 'other_countries') {
        defaultStructure[1].groupOptions = apiData?.[grp]?.map(c => {
          return {
            ...c,
            value: countryCode ? c.code : c?.name,
            customLabel: (
              <CountryCodeOption 
                details={c} 
                countryCode={countryCode} 
                countryName={countryName} 
              />
            ),
          };
        });
      }
    });

    setCountryCode(defaultValue);
    setCountryCodeDetails(defaultStructure);
    LocalStorageHelper.setIsdCodeGeo(defaultValue);
  }

  return (
    <UpSelectDropdown
      withGrouping
      options={countryCodeDetails}
      onValueChange={setCountryCode}
      value={value}
      isDisabled={isDisabled}
      optionProps={{ style: { minWidth: 200 } }}
      triggerProps={{ style: { width: '100%' } }}
    />
  );
}

function CountryCodeOption({ details, countryCode }) {
  return (
    <div className="flex items-center">
      <div className="w-5 h-5 mr-2">
        <img
          src={details.image_link || PlaceholderFlag}
          alt={details.name}
          className="w-full h-full object-contain"
        />
      </div>
      <span>{details.name}&nbsp;&#40;</span>
      <ItemText>
        <div className="flex items-center">
          <div className="w-6 h-6 mr-2 hidden flag-img">
            <img
              src={details.image_link || PlaceholderFlag}
              alt={details.name}
              className="w-full h-full object-contain"
            />
          </div>
          <span className="text">{countryCode ? details.code : details.name}</span>
        </div>
      </ItemText>
      <span>&#41;</span>
    </div>
  );
}

export default CountryCodeSelect;

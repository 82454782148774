import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import CardHeader from '../../components/CardHeader';
import UpInput from '../../components/Common/UpInput';
import UpButton from '../../components/Common/UpButton';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import UpToast from '../../components/Common/UpToast';
import { acceptInvite, verifyInviteOtp } from '../../api/auth';
import { getRedirectRoute } from '../../helpers/bizDataHelpers';
import { checkErrorTypeString } from '../../helpers/utils';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import ContactSupport from '../../components/ContactSupport';
import ReCAPTCHA from 'react-google-recaptcha';
import { getRecaptchaSiteKey } from '../../helpers/environment.js';
import AnalyticsService from '../../services/analytics';
import CaptchaHelper from "../../helpers/CaptchaHelper.js";

function AuthInvite() {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const { state } = useLocation();
  const [otp, setOtp] = useState('');
  const [inputError, setInputError] = useState('');
  const [resendCount, setResendCount] = useState(30);
  const [numOfTimesResendClicked, setNumOfTimesResendClicked] = useState(0);
  const [startCounter, setStartCounter] = useState(true);

  useEffect(() => {
    if (startCounter && resendCount > 0) {
      setTimeout(() => setResendCount(resendCount - 1), 1000);
    }

    if (resendCount === 0) {
      setStartCounter(false);
      setResendCount(30);
    }
  }, [resendCount, startCounter]);

  const { isLoading: verifyLoading, mutate: callVerifyInviteOtp } = useMutation(
    {
      mutationFn: ({ params }) => verifyInviteOtp(params),
      onSuccess: resp => {
        const redirect = getRedirectRoute(resp);
        navigate('/invite/complete', { state: { redirect } });
      },
      onError: error => {
        const errorMessage = checkErrorTypeString(
          error?.response?.data?.message,
        );

        if (error?.response?.data?.err_text === 'INVALID_RECAPTCHA') {
          UpToast.error(errorMessage);
        } else {
          setInputError(errorMessage);
        }
      },
    },
  );

  const { isLoading: acceptLoading, mutate: callAcceptInvite } = useMutation({
    mutationFn: ({ params }) => acceptInvite(params),
    onSuccess: response => {
      setStartCounter(true);
      UpToast.success('OTP sent successfully.');

      if (response?.access_token) {
        LocalStorageHelper.setAccessToken(response?.access_token);
      }
    },
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
    },
  });

  async function handleSubmit(e) {
    e.preventDefault();

    if (!otp) {
      return;
    }

    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    callVerifyInviteOtp({
      params: { otp, re_captcha: captchaToken },
    });
  }

  async function onResendOtp() {
    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    const isValidPhone = isValidPhoneNumber(
      `${state?.userData?.isd_code}${state?.otpSentOn}`,
    );

    // AnalyticsService.onResendOtpButton(
    //   'invite-verify',
    //   isValidPhone ? 'phone' : 'email',
    // );

    callAcceptInvite({
      params: { user_data: state?.userData, re_captcha: captchaToken },
    });
	setNumOfTimesResendClicked(numOfTimesResendClicked + 1);
  }

  function onOtpChange(updatedValue) {
    setInputError('');
    setOtp(updatedValue);
  }

  return (
    <div className="mb-8">
      <UpLoadingBar loading={verifyLoading || acceptLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={verifyLoading || acceptLoading} />
        <div>
          <CardHeader title="Authentication Required" />
          <div className="flex items-center mt-3">
            <div className="text-sm text-up-clr-dark-grey mr-1">
              OTP sent to{' '}
              <span className="font-semibold">{state?.otpSentOn}</span>
            </div>
            <UpButton
              variant="link"
              loading={verifyLoading || acceptLoading}
              onClick={() => navigate('/invite/register')}>
              Change
            </UpButton>
          </div>
          <form onSubmit={handleSubmit}>
            <UpInput
              type="number"
              name="otp"
              label="Enter OTP"
              placeholder="xxx-xxx"
              wrapperClass="mt-12 mb-8"
              value={otp}
              onChange={onOtpChange}
              errorContent={inputError}
              extraAction={
                startCounter ? (
                  <div className="text-up-clr-light-grey font-semibold text-sm text-right">
                    Resend OTP in 00:{('0' + resendCount).slice(-2)}
                  </div>
                ) : (
                  <UpButton
                    variant="link"
                    onClick={onResendOtp}
                    wrapperClass="w-max ml-auto">
                    Resend OTP
                  </UpButton>
                )
              }
            />
            <UpButton type="submit">Verify</UpButton>
          </form>
          {numOfTimesResendClicked >= 2 ? <ContactSupport screen="invite-verify" /> : null}
        </div>
      </div>

      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        badge="bottomleft"
        sitekey={getRecaptchaSiteKey()}
      />
    </div>
  );
}

export default AuthInvite;

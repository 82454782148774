import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { getLinkedAccounts } from '../../helpers/bizDataHelpers';
import PlaceholderBiz from '../../assets/images/placeholder-biz.svg';

function LinkedAccounts() {
  const linkedAccountsData = getLinkedAccounts();

  useEffect(() => {
    const emptyIds = [];

    linkedAccountsData?.forEach(linked => {
      linked?.accounts?.forEach(acc => {
        if (!acc?.app_biz_logo && !acc?.app_biz_name) {
          emptyIds.push(acc?.app_biz_id);
        }
      });
    });

    if (emptyIds?.length) {
      Sentry.captureMessage('Biz name and logo not found in appsbiz data', {
        extra: { app_biz_id: emptyIds },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {linkedAccountsData?.map(linked => {
        if (!linked?.accounts || linked?.accounts?.length === 0) {
          return null;
        }

        return (
          <div key={linked.platform}>
            <p className="text-xs font-semibold text-up-clr-dark-grey px-3 py-2">
              {linked?.platform?.toUpperCase()}
            </p>
            <div>
              {linked?.accounts?.map(acc => {
                return (
                  <div
                    key={acc.app_biz_id}
                    className="p-3 flex items-center border-t border-up-clr-neutral-grey">
                    <div className="w-6 h-6 mr-2">
                      <img
                        src={acc.app_biz_logo || acc.biz_logo || PlaceholderBiz}
                        alt={acc.app_biz_name || acc.biz_name}
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <div>
                      <p className="text-xs text-up-clr-black">
                        {acc.app_biz_name || acc.biz_name}
                      </p>
                      <p
                        style={{ fontSize: 8 }}
                        className="text-up-clr-dark-grey">
                        {(acc.roles_name || []).join(', ')}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default LinkedAccounts;

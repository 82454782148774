import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CardHeader from '../../components/CardHeader';
import UpInput from '../../components/Common/UpInput';
import UpToast from '../../components/Common/UpToast';
import UpButton from '../../components/Common/UpButton';
import {
  resetPassword,
  resetPasswordVerify,
  verifyUserToken,
} from '../../api/auth';
import { checkErrorTypeString } from '../../helpers/utils';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import MagicWandIcon from '../../assets/icons/MagicWandIcon';
import ContactSupport from '../../components/ContactSupport';
import { getRecaptchaSiteKey } from '../../helpers/environment.js';
import AnalyticsService from '../../services/analytics';
import CaptchaHelper from "../../helpers/CaptchaHelper.js";

function VerifyView() {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [otp, setOtp] = useState('');
  const [searchParams] = useSearchParams();
  const [inputError, setInputError] = useState('');
  const [resendCount, setResendCount] = useState(30);
  const [numOfTimesResendClicked, setNumOfTimesResendClicked] = useState(0);
  const [startCounter, setStartCounter] = useState(true);
  const isAppLogin = LocalStorageHelper.getAppLogin();
  const { selectedLogin, isMobile, emailMobile, countryCode } =
    LocalStorageHelper.getUserDetails();

  useEffect(() => {
    if (searchParams.get('token')) {
      LocalStorageHelper.setAccessToken(searchParams.get('token'));
      callVerifyUserToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: tokenLoading, mutate: callVerifyUserToken } = useMutation({
    mutationFn: verifyUserToken,
    onSuccess: onVerifyUserSuccess,
    onError: onVerifyUserError,
  });

  function onVerifyUserSuccess(resp) {
    if (resp?.access_token) {
      LocalStorageHelper.setAccessToken(resp?.access_token);
    }

    if (resp?.email) {
      LocalStorageHelper.setUserDetails({
        selectedLogin: resp?.email,
        isMobile,
        emailMobile: resp?.email,
        countryCode,
      });
    }

    navigate('/forgot-password/reset');
  }

  function onVerifyUserError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);

    UpToast.error(errorMessage);
  }

  const { isLoading: isResetLoading, mutate: callResetPassword } = useMutation({
    mutationFn: ({ loginSource, params }) => resetPassword(loginSource, params),
    onError: onResetPasswordError,
    onSuccess: onResetPasswordSuccess,
  });

  function onResetPasswordSuccess(resp) {
    setOtp('');
    setInputError('');
    setStartCounter(true);
    UpToast.success('OTP sent successfully.');

    if (resp?.access_token) {
      LocalStorageHelper.setAccessToken(resp?.access_token);
    }
  }

  function onResetPasswordError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);

    UpToast.error(errorMessage);
  }

  const { isLoading: isVerifyLoading, mutate: callResetPasswordVerify } =
    useMutation({
      mutationFn: ({ authType, loginSource, params }) =>
        resetPasswordVerify(authType, loginSource, params),
      onSuccess: resp => {
        if (resp?.access_token) {
          LocalStorageHelper.setAccessToken(resp?.access_token);
        }

        navigate('/forgot-password/reset');
      },
      onError: err => {
        const errorMessage = checkErrorTypeString(err?.response?.data?.message);

        if (err?.response?.data?.err_text === 'INVALID_RECAPTCHA') {
          UpToast.error(errorMessage);
        } else {
          setInputError(errorMessage);
        }
      },
    });

  useEffect(() => {
    if (startCounter && resendCount > 0) {
      setTimeout(() => setResendCount(resendCount - 1), 1000);
    }

    if (resendCount === 0) {
      setStartCounter(false);
      setResendCount(30);
    }
  }, [resendCount, startCounter]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!otp) {
      return;
    }

    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    const params = {
      otp,
      value: emailMobile,
      type: isMobile ? 'phone' : 'email',
      isd_code: isMobile ? countryCode : '',
      re_captcha: captchaToken,
    };

    callResetPasswordVerify({
      params,
      authType: 'otp',
      loginSource: isMobile ? 'phone' : 'email',
    });
  }

  async function onResendOtp() {
    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    // AnalyticsService.onResendOtpButton(
    //   'reset-password-verify',
    //   isMobile ? 'phone' : 'email',
    // );

    callResetPassword({
      loginSource: isMobile ? 'phone' : 'email',
      params: {
        value: emailMobile,
        type: isMobile ? 'phone' : 'email',
        isd_code: isMobile ? countryCode : '',
        re_captcha: captchaToken,
      },
    });
	setNumOfTimesResendClicked(numOfTimesResendClicked + 1);
  }
	 
  function onOtpChange(updatedValue) {
    setInputError('');
    setOtp(updatedValue);
  }

  const viewLoading = isResetLoading || isVerifyLoading || tokenLoading;

  return (
    <div>
      <UpLoadingBar loading={viewLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={viewLoading} />
        <div>
          <CardHeader title="Authentication Required" />
          <div className="text-sm text-up-clr-dark-grey mt-3">
            OTP sent to <span className="font-semibold">{selectedLogin}</span>
          </div>
          <form onSubmit={handleSubmit}>
            <UpInput
              autoFocus
              loading={viewLoading}
              type="number"
              name="otp"
              label="Enter OTP"
              placeholder="xxx-xxx"
              wrapperClass="mt-12 mb-8"
              value={otp}
              onChange={onOtpChange}
              errorContent={inputError}
              extraAction={
                startCounter ? (
                  <div className="text-up-clr-light-grey font-semibold text-sm text-right">
                    Resend OTP in 00:{('0' + resendCount).slice(-2)}
                  </div>
                ) : (
                  <UpButton
                    loading={viewLoading}
                    variant="link"
                    onClick={onResendOtp}
                    wrapperClass="w-max ml-auto">
                    Resend OTP
                  </UpButton>
                )
              }
            />
            {!isMobile && !isAppLogin && (
              <div className="bg-up-clr-lightest-grey p-3 flex rounded-sm">
                <MagicWandIcon />
                <div className="text-up-clr-black text-sm ml-2">
                  You can also use the magic link provided in the OTP email to
                  verify directly
                </div>
              </div>
            )}
            <UpButton type="submit" wrapperClass="mt-8" loading={viewLoading}>
              Verify
            </UpButton>
          </form>
		  {numOfTimesResendClicked >= 2 ? <ContactSupport screen="reset-password-verify" /> : null}
        </div>
      </div>

      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        badge="bottomleft"
        sitekey={getRecaptchaSiteKey()}
      />
    </div>
  );
}

export default VerifyView;

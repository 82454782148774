import AxiosInstance from '../services/axios';

/**
 * Get country codes data
 */
export async function getCountryCodes(params) {
  const response = await AxiosInstance.get('/api/auth/v1/country-codes', {
    params,
  });
  return response.data;
}

/**
 * User login
 */
export async function loginUser(params) {
  const response = await AxiosInstance.post(
    '/api/auth/v1/login/verify',
    params,
  );
  return response.data;
}

/**
 * User logout
 */
export async function logoutUser() {
  const response = await AxiosInstance.post('/api/auth/v1/logout/auth-service');
  return response.data;
}

/**
 * User login via Prime creds
 */
export async function loginPrimeUser(params) {
  const response = await AxiosInstance.post(
    '/api/auth/v1/login/prime-creds/verify',
    params,
  );
  return response.data;
}

/**
 * User login via otp - initiate
 */
export async function loginOtpInitiate(authType, loginSource, params) {
  const response = await AxiosInstance.post(
    `/api/auth/v1/login/${authType}/${loginSource}/initiate`,
    params,
  );
  return response.data;
}

/**
 * User login via otp - verify
 */
export async function loginOtpVerify(authType, loginSource, params) {
  const response = await AxiosInstance.post(
    `/api/auth/v1/login/${authType}/${loginSource}/verify`,
    params,
  );
  return response.data;
}

/**
 * Create new password
 */
export async function createNewPassword(loginSource, params) {
  const response = await AxiosInstance.post(
    `/api/auth/v1/reset_password/${loginSource}`,
    params,
  );
  return response.data;
}

/**
 * Redirect to platform
 */
export async function redirectToPlatform(appType, params) {
  const response = await AxiosInstance.post(
    `/api/auth/v1/client/${appType}/redirect`,
    params,
  );
  return response.data;
}

/**
 * Reset password
 */
export async function resetPassword(loginSource, params) {
  const response = await AxiosInstance.post(
    `/api/auth/v1/reset_password/${loginSource}/initiate`,
    params,
  );
  return response.data;
}

/**
 * Reset password otp or mail verification
 */
export async function resetPasswordVerify(authType, loginSource, params) {
  const response = await AxiosInstance.post(
    `/api/auth/v1/reset_password/${authType}/${loginSource}/verify`,
    params,
  );
  return response.data;
}

/**
 * Update unique creds for a user
 */
export async function updateUniqueCreds(loginSource, params) {
  const response = await AxiosInstance.post(
    `/api/auth/v1/login/${loginSource}/merge`,
    params,
  );
  return response.data;
}

/**
 * Verify user token on page load
 */
export async function verifyUserToken() {
  const response = await AxiosInstance.post('/api/auth/v1/token/verify');
  return response.data;
}

/**
 * Initiate accept invite
 */
export async function acceptInvite(params) {
  const response = await AxiosInstance.post(
    '/api/auth/v1/invite/initiate',
    params,
  );
  return response.data;
}

/**
 * Verify invite
 */
export async function verifyInvite(params) {
  const response = await AxiosInstance.post(
    '/api/auth/v1/invite/verify',
    params,
  );
  return response.data;
}

/**
 * Verify invite short link
 */
export async function verifyInviteShortLink(params) {
  const response = await AxiosInstance.post(
    '/api/auth/v1/invite/short-link/verify',
    params,
  );
  return response.data;
}

/**
 * Verify invite otp
 */
export async function verifyInviteOtp(params) {
  const response = await AxiosInstance.post(
    '/api/auth/v1/invite/otp/verify',
    params,
  );
  return response.data;
}

/**
 * Self service onboarding: Get restaurant details
 */
export async function getRestaurantDetails(token, params) {
  const response = await AxiosInstance.get('/api/auth/v1/onboarding/biz-info', {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Self service onboarding: Save restaurant details
 */
export async function saveRestaurantDetails(token, params) {
  const response = await AxiosInstance.put('/api/auth/v1/onboarding/biz-info', params, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Platforms list
 */
export async function getPlatformsList(token, params) {
  const response = await AxiosInstance.get('/api/auth/v1/platforms', {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Cities list
 */
export async function getCitiesList(token, params = {}) {
  if (!params.country || !params.city) {
    return { data: [] };
  }

  let url = '/api/auth/v1/cities/search?limit=100';
  url += params?.country ? `&country=${params?.country}` : '';
  url += params?.city ? `&city=${params?.city}` : '';

  const response = await AxiosInstance.get(url, {
    headers: {
      "Authorization": `Bearer ${token}`
    },
  });
  return response.data;
}

import React from 'react';
import './style.scss';

function UpLoadingCover({ loading }) {
  if (!loading) {
    return null;
  }

  return <div className="up-loading-cover" />;
}

export default UpLoadingCover;

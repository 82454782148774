import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import LoadingCard from '../../components/LoadingCard';
import { logoutUser } from '../../api/auth';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import AnalyticsService from "../../services/analytics";
import Vent from '../../vent';

function Logout() {
  const navigate = useNavigate();

  const { mutate: callLogoutUser } = useMutation({
    mutationFn: logoutUser,
    onError: onLogoutUserComplete,
    onSuccess: onLogoutUserComplete,
  });

  useEffect(() => {
    callLogoutUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onLogoutUserComplete() {
    AnalyticsService.onUleLogout();
    LocalStorageHelper.clearUserDataOnLogout();
    navigate('/login/email-mobile');
    Vent.onUserUnidentified();
  }

  return <LoadingCard />;
}

export default Logout;

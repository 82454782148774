import Axios from 'axios';
import LocalStorageHelper from '../helpers/localStorageHelpers';

const AxiosInstance = Axios.create({
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
  },
});

AxiosInstance.interceptors.request.use(
  config => {
    config.headers['X-Client'] = 'up-login';
    config.headers['X-Source'] = 'up-login';

    const accessToken = LocalStorageHelper.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

AxiosInstance.interceptors.response.use(
  response => response,
  error => {
    const errorMessage =
      error?.response?.data?.message ||
      'Something went wrong. Please contact support if the issue persists.';
    console.log(errorMessage);
    throw error;
  },
);

export default AxiosInstance;

import React from 'react';

function DownArrowIcon(props) {
  return (
    <span>
      <svg
        width="18"
        height="18"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M4.95312 6.3335L3.95312 7.3335L8 11.3804L12.0469 7.3335L11.0469 6.3335L8 9.38037L4.95312 6.3335Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

export default DownArrowIcon;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { createNewPassword } from '../../api/auth';
import LinkedAccounts from '../../components/LinkedAccounts';
import UserIcon from '../../assets/icons/UserIcon';
import CardHeader from '../../components/CardHeader';
import UpInput from '../../components/Common/UpInput';
import UpToast from '../../components/Common/UpToast';
import UpButton from '../../components/Common/UpButton';
import UpPopover from '../../components/Common/UpPopover';
import { checkErrorTypeString, isValidPassword } from '../../helpers/utils';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import AnalyticsService from '../../services/analytics';

function SetPassword() {
  const navigate = useNavigate();
  const { selectedLogin, isMobile } = LocalStorageHelper.getUserDetails();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const { isLoading, mutate: callCreatePassword } = useMutation({
    mutationFn: ({ loginSource, params }) =>
      createNewPassword(loginSource, params),
    onSuccess: resp => {
      UpToast.success(resp?.message);
      LocalStorageHelper.setAccessToken('');
      navigate('/login/password');
    },
    onError: err => {
      const errorMessage = checkErrorTypeString(err?.response?.data?.message);

      setConfirmPasswordError(errorMessage);
    },
  });

  // useEffect(() => {
  //   AnalyticsService.onUleNewPasswordPage();
  // }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match. Try again.');
      return;
    }

    if (
      !newPassword ||
      !confirmPassword ||
      newPasswordError ||
      confirmPasswordError
    ) {
      return;
    }

    if (!isValidPassword(newPassword)) {
      setNewPasswordError(
        'Password must contain 8 characters, 1 number and 1 special character.',
      );
      return;
    }

    callCreatePassword({
      loginSource: isMobile ? 'phone' : 'email',
      params: {
        password1: newPassword,
        password2: confirmPassword,
      },
    });
  }

  function onNewPasswordChange(updatedValue) {
    setNewPasswordError('');
    setNewPassword(updatedValue);
  }

  function onNewPasswordBlur() {
    if (!newPassword) {
      return;
    }

    if (isValidPassword(newPassword)) {
      setNewPasswordError('');
      return;
    }

    setNewPasswordError(
      'Password must contain 8 characters, 1 number and 1 special character.',
    );
  }

  function onConfirmPasswordChange(updatedValue) {
    setConfirmPasswordError('');
    setConfirmPassword(updatedValue);
  }

  return (
    <div>
      <UpLoadingBar loading={isLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={isLoading} />
        <div>
          <CardHeader title="Create New Password" />
          <div className="text-sm text-up-clr-dark-grey my-3">
            <span>Create a common password for all accounts linked to </span>
            <span className="font-semibold mt-1">{selectedLogin}</span>
          </div>
          <UpPopover
            arrowAlign="start"
            contentSide="right"
            content={<LinkedAccounts />}>
            <UpButton variant="link" wrapperClass="w-max -ml-2 text-xs">
              View linked accounts
            </UpButton>
          </UpPopover>
          <div className="bg-up-blue-200 p-3 flex rounded-sm mt-3 mb-8">
            <UserIcon />
            <div className="text-up-clr-dark-grey text-sm ml-2">
              We found multiple accounts registered with the same mobile number.
              To create a smooth unified login experience please create a new
              password for common access.
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <UpInput
              autoFocus
              loading={isLoading}
              type="password"
              name="new_password"
              label="New Password"
              placeholder="New Password"
              helpContent="Password must contain 8 characters, 1 number and 1 special character."
              onBlur={onNewPasswordBlur}
              errorContent={newPasswordError}
              value={newPassword}
              onChange={onNewPasswordChange}
            />
            <UpInput
              loading={isLoading}
              type="password"
              name="confirm_password"
              label="Confirm Password"
              placeholder="Confirm Password"
              wrapperClass="mt-4 mb-8"
              errorContent={confirmPasswordError}
              value={confirmPassword}
              onChange={onConfirmPasswordChange}
            />
            <UpButton loading={isLoading} type="submit">
              Reset Password
            </UpButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;

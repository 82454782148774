import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

/**
 * Track mixpanel event
 */
function trackMixpanelEvent(eventName, eventProps = {}) {
  if (import.meta.env.ULE_BUILD_ENV !== 'production') {
    return;
  }

  mixpanel.track(eventName, eventProps);
}

/**
 * Identify mixpanel user
 */
function identifyMixpanelUser(user) {
  if (import.meta.env.ULE_BUILD_ENV !== 'production') {
    return;
  }

  if (!user) {
    removeMixpanelUser();
    return;
  }

  mixpanel.identify(`${user?.emailMobile}`);

  const commonProperties = {
    email_mobile: user?.emailMobile,
    country_code: user?.countryCode,
  };

  mixpanel.people.set({ ...commonProperties });

  const superProperties = {
    ...commonProperties,
    platform: 'ule',
  };

  mixpanel.register({ ...superProperties });
}

/**
 * Remove mixpanel user
 */
function removeMixpanelUser() {
  if (import.meta.env.ULE_BUILD_ENV !== 'production') {
    return;
  }

  mixpanel.reset();
}

/**
 * Track posthog event
 */
function trackPostHogEvent(eventName, eventProps = {}) {
  posthog?.capture(eventName, eventProps);
}

/**
 * Identify posthog user
 */
function identifyPostHogUser(user) {
  if (!user) {
    removePostHogUser();
    return;
  }

  const commonProperties = {}
  if (user?.isMobile) {
    commonProperties["phone"] = user?.emailMobile;
    commonProperties["isd_code"] = user?.countryCode;
  } else {
    commonProperties["email"] = user?.emailMobile;
  }

  posthog?.identify(`${user?.selectedLogin}`, {
    ...commonProperties,
  });

  const superProperties = {
    ...commonProperties,
    source: 'ULE',
  }

  posthog?.register({ ...superProperties });
}

/**
 * Remove posthog user
 */
function removePostHogUser() {
  posthog?.reset();
}

/**
 * When user visits landing page
 */
function onUleLandingPage(redirect) {
  trackMixpanelEvent('ule_landing_page', { redirect });
}

/**
 * When user clicks on help video on landing page
 */
function onUleHelpVideo(redirect) {
  trackMixpanelEvent('ule_help_video', { redirect });
}

/**
 * When user visits password page
 */
function onUlePasswordPage() {
  trackMixpanelEvent('ule_password_page');
}

/**
 * When user visits otp page
 */
function onUleOtpPage() {
  trackMixpanelEvent('ule_otp_page');
}

/**
 * When user visits deduplicate merge page
 */
function onUleMergePage() {
  trackMixpanelEvent('ule_deduplicate_page');
}

/**
 * When user visits new password page
 */
function onUleNewPasswordPage() {
  trackMixpanelEvent('ule_new_password_page');
}

/**
 * When user visits reset password page
 */
function onUleResetPasswordPage() {
  trackMixpanelEvent('ule_reset_password_page');
}

/**
 * When user logins
 */
function onUleLogin(params) {
  // trackMixpanelEvent('ule_login', params);
  trackPostHogEvent('ule_login', params);
}

/**
 * When user logouts
 */
function onUleLogout() {
  // trackMixpanelEvent('ule_logout');
  trackPostHogEvent('ule_logout');
}

/**
 * When user clicks on help/support links
 */
function onHelpSupportLink(form, screen) {
  trackMixpanelEvent('ule_help_support_link', { form, screen });
}

/**
 * When user clicks on resend OTP button
 */
function onResendOtpButton(screen, identifier) {
  trackMixpanelEvent('ule_resend_otp_button', { screen, identifier });
}

/**
 * Old login flow initiated
 */
function onOldLoginFlow(app, bizId) {
  trackMixpanelEvent('ule_old_login_flow', { app, biz_id: bizId });
}

const AnalyticsService = {
  identifyMixpanelUser,
  removeMixpanelUser,
  identifyPostHogUser,
  removePostHogUser,

  onUleLandingPage,
  onUleHelpVideo,
  onUlePasswordPage,
  onUleOtpPage,
  onUleMergePage,
  onUleNewPasswordPage,
  onUleResetPasswordPage,
  onUleLogin,
  onUleLogout,
  onHelpSupportLink,
  onResendOtpButton,
  onOldLoginFlow,
};

export default AnalyticsService;

import React, { useEffect } from 'react';
import UpBlackImg from '../../assets/images/up-black.svg';
import './style.scss';

function WelcomeScreen() {
  return (
    <div className="welcome-screen-wrapper">
      <div className="circle-top-right"></div>
      <div className="welcome-text text-white">Welcome to UrbanPiper</div>
      <img
        src={UpBlackImg}
        alt="urbanpiper"
        className="up"
      />
      <div className="circle-bottom-left"></div>
    </div>
  );
}
export default WelcomeScreen;

import React from 'react';
import './style.scss';

function UpLoadingBar({ loading }) {
  if (!loading) {
    return <div className="h-2" />;
  }

  return (
    <div className="loading-bar-wrapper">
      <div className="up-loading-bar" />
    </div>
  );
}

export default UpLoadingBar;

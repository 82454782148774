import React from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import UpVioletImg from '../../assets/images/up-violet.svg';
import OmkVioletImg from '../../assets/images/omk-violet.svg';
import UpButton from '../Common/UpButton';
import { OMK_COUNTRIES } from "../../helpers/bizDataHelpers";

function TopBar({ showLogout = true, isSticky = false, showContentRight = false, renderContentRight }) {
  const navigate = useNavigate();
  const countryOrigin = window?.GEO_DATA?.country || "IN";

  function getLogoStyles() {
    if (OMK_COUNTRIES.includes(countryOrigin)) {
      return {
        width: 245,
        minHeight: 24
      };
    }
    return {
      width: 115,
      minHeight: 24
    };
  }

  return (
    <div
      className={cx(
        'bg-white py-5 px-4 md:px-5 flex justify-between items-center top-0 z-50',
        showLogout || isSticky ? 'sticky' : 'sticky lg:hidden',
      )}>
      <div style={getLogoStyles()}>
        <img
          src={OMK_COUNTRIES.includes(countryOrigin) ? OmkVioletImg : UpVioletImg}
          alt="urbanpiper"
          className="w-full h-full object-contain"
        />
      </div>
      {showLogout && (
        <UpButton
          variant="link"
          wrapperClass="w-max ml-auto"
          onClick={() => navigate('/logout')}>
          Logout
        </UpButton>
      )}
      {showContentRight && (
        <div className="content-right">{renderContentRight}</div>
      )}
    </div>
  );
}

export default TopBar;

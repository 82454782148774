import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import Root from '../views/Root';
import Login from '../views/Login';
import Logout from '../views/Logout';
import OtpView from '../views/Login/OtpView';
import SetPassword from '../views/SetPassword';
import SelectBrand from '../views/SelectBrand';
import UpdateAccount from '../views/UpdateAccount';
import SelectPlatform from '../views/SelectPlatform';
import SelectBusiness from '../views/SelectBusiness';
import PageNotFound from '../components/PageNotFound';
import PasswordView from '../views/Login/PasswordView';
import VerifyView from '../views/ForgotPassword/VerifyView';
import EmailMobileView from '../views/Login/EmailMobileView';
import ResetPassword from '../views/ForgotPassword/ResetPassword';
import AuthInvite from '../views/Invite/AuthInvite';
import VerifyInvite from '../views/Root/VerifyInvite';
import AcceptInvite from '../views/Invite/AcceptInvite';
import RegisterInvite from '../views/Invite/RegisterInvite';
import CompleteInvite from '../views/Invite/CompleteInvite';
import OldLogin from "../views/Login/OldLogin.jsx";
import RestrictLogin from "../views/PrimeLogin/RestrictLogin.jsx";
import PrimeLogin from "../views/PrimeLogin/index.jsx";
import OnboardingForm from "../views/Onboarding/OnboradingForm.jsx";
import WelcomeScreen from "../views/Onboarding/WelcomeScreen.jsx";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: 'update-account',
        element: <UpdateAccount />,
      },
      {
        path: 'set-password',
        element: <SetPassword />,
      },
      {
        path: 'business',
        element: <SelectBusiness />,
      },
      {
        path: 'restrict-login',
        element: <RestrictLogin />
      },
      {
        path: 'business/:bizId',
        element: <SelectPlatform />,
      },
      {
        path: 'business/:bizId/:platform',
        element: <SelectBrand />,
      },
      {
        path: 's/:inviteId',
        element: <VerifyInvite />,
      },
      {
        path: 'forgot-password',
        children: [
          {
            path: '',
            element: <Navigate to="/forgot-password/verify" replace />,
          },
          {
            path: 'verify',
            element: <VerifyView />,
          },
          {
            path: 'reset',
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: 'invite',
        children: [
          {
            path: '',
            element: <Navigate to="/invite/accept" replace />,
          },
          {
            path: 'accept',
            element: <AcceptInvite />,
          },
          {
            path: 'register',
            element: <RegisterInvite />,
          },
          {
            path: 'auth',
            element: <AuthInvite />,
          },
          {
            path: 'complete',
            element: <CompleteInvite />,
          },
        ],
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: '',
        element: <Navigate to="/login/email-mobile" replace />,
      },
      {
        path: 'email-mobile',
        element: <EmailMobileView />,
      },
      {
        path: 'password',
        element: <PasswordView />,
      },
      {
        path: 'otp',
        element: <OtpView />,
      },
      {
        path: 'old-account',
        element: <OldLogin />
      },
      {
        path: 'prime',
        element: <PrimeLogin />
      },
    ],
  },
  {
    path: '/onboarding',
    errorElement: <PageNotFound />,
    children: [
      {
        path: '',
        element: <Navigate to="/onboarding/biz-verify" replace />,
      },
      {
        path: 'biz-verify',
        element: <OnboardingForm />,
      },
      {
        path: 'complete',
        element: <WelcomeScreen />,
      },
    ],
  }
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;

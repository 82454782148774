import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import CardHeader from '../../components/CardHeader';
import UpInput from '../../components/Common/UpInput';
import UpButton from '../../components/Common/UpButton';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import { loginPrimeUser } from '../../api/auth';
import { checkErrorTypeString } from '../../helpers/utils';

function PrimeLogin() {
  const navigate = useNavigate();
  const [domain, setDomain] = useState('');
  const [domainError, setDomainError] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { isLoading, mutate: callLoginPrimeUser } = useMutation({
    mutationFn: loginPrimeUser,
    onError: onLoginPrimeError,
    onSuccess: onLoginPrimeSuccess,
  });

  function onLoginPrimeSuccess(resp) {
    const userData = {
      email: resp?.data?.prime_data?.email_verified_at
        ? resp?.data?.prime_data?.email
        : null,
      phone: resp?.data?.prime_data?.mobile_verified_at
        ? resp?.data?.prime_data?.phone
        : null,
      isAuthServiceUser: resp?.data?.prime_data?.exists_in_auth_service,
    };

    navigate('/restrict-login', { state: { ...userData } });
  }

  function onLoginPrimeError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);
    setDomainError(errorMessage);
  }

  function handleSubmit(e) {
    e.preventDefault();
    let formError = false;

    if (!domain) {
      setDomainError('Please enter company name');
      formError = true;
    }

    if (!username) {
      setUsernameError('Please enter username');
      formError = true;
    }

    if (!password) {
      setPasswordError('Please enter password');
      formError = true;
    }

    if (formError) {
      return;
    }

    callLoginPrimeUser({
      domain,
      username,
      password,
    });
  }

  function onDomainChange(updated) {
    setDomainError('');
    setDomain(updated);
  }

  function onUsernameChange(updated) {
    setUsernameError('');
    setUsername(updated);
  }

  function onPasswordChange(updated) {
    setPasswordError('');
    setPassword(updated);
  }

  return (
    <div>
      <UpLoadingBar loading={isLoading} />
      <div className="dark-view-wrapper relative">
        <UpLoadingCover loading={isLoading} />
        <div className="flex-grow">
          <CardHeader title="Login with Prime credentials" />
          <form onSubmit={handleSubmit}>
            <UpInput
              autoFocus
              loading={isLoading}
              type="text"
              name="company"
              wrapperClass="mt-12 mb-4"
              label="Company Name"
              placeholder="Ex: pipercafe"
              value={domain}
              onChange={onDomainChange}
              errorContent={domainError}
            />
            <UpInput
              loading={isLoading}
              type="text"
              name="username"
              label="Username"
              placeholder="Ex: johndoe"
              value={username}
              onChange={onUsernameChange}
              errorContent={usernameError}
            />
            <UpInput
              loading={isLoading}
              type="password"
              name="password"
              label="Password"
              placeholder="Password"
              wrapperClass="mt-4 mb-9"
              value={password}
              onChange={onPasswordChange}
              errorContent={passwordError}
            />
            <UpButton type="submit" loading={isLoading} wrapperClass="mb-5">
              Continue
            </UpButton>
          </form>
          <UpButton
            variant="link"
            loading={isLoading}
            onClick={() => navigate('/login/email-mobile')}>
            Login with email/mobile number instead
          </UpButton>
        </div>
      </div>
    </div>
  );
}

export default PrimeLogin;

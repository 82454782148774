import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import UserIcon from '../../assets/icons/UserIcon';
import CardHeader from '../../components/CardHeader';
import UpButton from '../../components/Common/UpButton';
import UpPopover from '../../components/Common/UpPopover';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import LinkedAccounts from '../../components/LinkedAccounts';
import UpSelectDropdown from '../../components/Common/UpSelectDropdown';
import { updateUniqueCreds } from '../../api/auth';
import { checkErrorTypeString } from '../../helpers/utils';
import PathStatus from '../../constants/PathStatus';
import AnalyticsService from '../../services/analytics';

function UpdateAccount() {
  const navigate = useNavigate();
  const [selectedEmail, setSelectedEmail] = useState();
  const [selectedMobile, setSelectedMobile] = useState();
  const [errorContent, setErrorContent] = useState('');
  const { isMobile, selectedLogin } = LocalStorageHelper.getUserDetails();
  const duplicateData = LocalStorageHelper.getDuplicateData();

  const { isLoading, mutate: callUpdateUniqueCreds } = useMutation({
    mutationFn: ({ loginSource, params }) =>
      updateUniqueCreds(loginSource, params),
    onError: onUpdateUniqueCredsError,
    onSuccess: onUpdateUniqueCredsSuccess,
  });

  // useEffect(() => {
  //   AnalyticsService.onUleMergePage();
  // }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (!selectedMobile && !selectedEmail) {
      return;
    }

    const isdCode = duplicateData?.phones?.find(
      p => p?.phone === selectedMobile,
    )?.isd_code;

    callUpdateUniqueCreds({
      loginSource: isMobile ? 'phone' : 'email',
      params: {
        type: isMobile ? 'email' : 'phone',
        isd_code: isdCode && !isMobile ? '+91' : '',
        value: isMobile ? selectedEmail : selectedMobile,
      },
    });
  }

  function onUpdateUniqueCredsSuccess(resp) {
    if (resp?.access_token) {
      LocalStorageHelper.setAccessToken(resp?.access_token);
    }

    if (resp?.path_status === PathStatus.CREATE_NEW_PASSWORD) {
      navigate('/set-password');
    }
  }

  function onUpdateUniqueCredsError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);
    setErrorContent(errorMessage);
  }

  return (
    <div>
      <UpLoadingBar loading={isLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={isLoading} />
        <div>
          <CardHeader
            title={
              isMobile
                ? 'Select Preferred Email Address'
                : 'Select Preferred Mobile Number'
            }
          />
          <div className="text-sm text-up-clr-dark-grey mt-2">
            <span>
              {isMobile
                ? 'For your account linked to mobile number : '
                : 'For your account linked to email : '}
            </span>
            <span className="font-semibold mt-1">{selectedLogin}</span>
          </div>
          <div className="bg-up-blue-200 p-3 flex rounded-sm my-6">
            <UserIcon />
            <div className="text-up-clr-dark-grey text-sm ml-2">
              <div className="mb-1">
                {isMobile
                  ? 'We have detected multiple email IDs associated with your account, linked to multiple businesses.'
                  : 'We have detected multiple mobile numbers associated with your account, linked to multiple businesses.'}
              </div>
              <UpPopover
                arrowAlign="start"
                contentSide="right"
                content={<LinkedAccounts />}>
                <UpButton variant="link" wrapperClass="w-max -ml-2 text-xs">
                  View linked accounts
                </UpButton>
              </UpPopover>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            {isMobile ? (
              <UpSelectDropdown
                options={(duplicateData?.emails || []).map(e => ({
                  value: e,
                  label: e,
                }))}
                label="Email Address"
                placeholder="Select Email Address"
                value={selectedEmail}
                onValueChange={setSelectedEmail}
                optionProps={{ style: { minWidth: '100%' } }}
                triggerProps={{ style: { width: '100%' } }}
                errorContent={errorContent}
              />
            ) : (
              <UpSelectDropdown
                options={(duplicateData?.phones || []).map(p => ({
                  value: p?.phone,
                  label: `${p?.isd_code} ${p?.phone}`,
                }))}
                label="Mobile Number"
                placeholder="Select Mobile Number"
                value={selectedMobile}
                onValueChange={setSelectedMobile}
                optionProps={{ style: { minWidth: '100%' } }}
                triggerProps={{ style: { width: '100%' } }}
                errorContent={errorContent}
              />
            )}
            <UpButton type="submit" wrapperClass="mt-12">
              Update Account Details
            </UpButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateAccount;

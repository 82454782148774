import React from 'react';
import UpWhiteImg from '../../assets/images/up-white.svg';
import OmkWhiteImg from '../../assets/images/omk-white.svg';
import './style.scss';
import { OMK_COUNTRIES } from "../../helpers/bizDataHelpers";

function MarketingPosts() {
  const countryOrigin = window?.GEO_DATA?.country || "IN";

  function getLogoStyles() {
    if (OMK_COUNTRIES.includes(countryOrigin)) {
      return {
        width: 480,
        minHeight: 54
      };
    }
    return {
      width: 205,
      minHeight: 45
    };
  }

  return (
    <div className="h-full text-white mx-auto marketing-wrapper">
      <div style={getLogoStyles()}>
        <img
          src={OMK_COUNTRIES.includes(countryOrigin) ? OmkWhiteImg : UpWhiteImg}
          alt="urbanpiper"
          className="w-full h-full object-contain"
        />
      </div>
      <div className="login-text mt-2">LOGIN TO UNLOCK</div>
      <div className="restaurant-text">Your Restaurant&apos;s</div>
      <div className="unfair-text">Unfair Advantage</div>
    </div>
  );
}

export default MarketingPosts;

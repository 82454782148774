import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CallIcon from '../../assets/icons/CallIcon';
import MailIcon from '../../assets/icons/MailIcon';
import CardHeader from '../../components/CardHeader';
import UpButton from '../../components/Common/UpButton';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import { getBaseUrlPrime } from '../../helpers/environment';

function RestrictLogin() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div>
      <UpLoadingBar />
      <div className="light-view-wrapper relative">
        <UpLoadingCover />
        <div>
          <CardHeader title="Only mobile/email login allowed" />
          <div className="text-sm text-up-clr-dark-grey mt-5 mb-4">
            <span className="font-semibold mt-1">
              Login with Prime credentials has been disabled.{' '}
            </span>
            <span>
              For added security and a better signing in experience only mobile
              number and email login is accessible for your account.
            </span>
          </div>
          <div className="text-sm text-up-clr-dark-grey">
            Currently linked details shown below.
          </div>
          <div className="pt-2 mb-12 text-up-clr-black">
            {state?.phone && (
              <div className="flex mt-6">
                <CallIcon className="mt-1" />
                <div className="ml-3">
                  <p>Mobile Number</p>
                  <p className="font-semibold">{state?.phone}</p>
                </div>
              </div>
            )}
            {state?.email && (
              <div className="flex mt-6">
                <MailIcon className="mt-1" />
                <div className="ml-3">
                  <p>Email Address</p>
                  <p className="font-semibold">{state?.email}</p>
                </div>
              </div>
            )}
            {!state?.phone && !state?.email && (
              <div className="font-semibold mt-6">
                Neither of your email or mobile number are verified. Please{' '}
                <span className="text-up-blue">
                  <a href={`${getBaseUrlPrime()}/auth/login`}>
                    login to Prime on the old experience
                  </a>
                </span>{' '}
                to verify them.
              </div>
            )}
          </div>
          <UpButton onClick={() => navigate('/login/email-mobile')}>
            Login with email/mobile
          </UpButton>
          <div className="mt-3 text-sm text-up-clr-dark-grey text-center">
            {state?.isAuthServiceUser
              ? 'Please use the password set on the new login experience.'
              : 'You can use your current Prime password to login.'}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestrictLogin;

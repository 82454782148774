import React from 'react';

function QuestionIcon(props) {
  return (
    <span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M8 1.5C4.41025 1.5 1.5 4.41025 1.5 8C1.5 9.34262 1.90748 10.5902 2.60498 11.626L2 14.5L5.14209 13.8384C6.00477 14.2615 6.97436 14.5 8 14.5C11.5898 14.5 14.5 11.5898 14.5 8C14.5 4.41025 11.5898 1.5 8 1.5ZM8.06006 4.33398C9.20131 4.33398 9.97217 5.03286 9.97217 6.26611C9.97217 7.16036 9.42755 7.6945 8.8623 8.21875C8.34805 8.6915 8.35839 9.04099 8.32764 9.72949H7.21729V9.62695C7.21729 8.72245 7.52605 8.00301 8.0708 7.49951C8.64605 6.96501 8.81006 6.81086 8.81006 6.26611C8.81006 5.76261 8.52305 5.46484 8.03955 5.46484C7.4543 5.46484 6.97059 5.63931 6.61084 6.09131L6.05664 5.17676C6.49814 4.59101 7.15556 4.33398 8.06006 4.33398ZM7.78271 10.5757C8.14246 10.5757 8.38916 10.9047 8.38916 11.3262C8.38916 11.7267 8.14246 12.0659 7.78271 12.0659C7.44346 12.0659 7.15576 11.7269 7.15576 11.3262C7.15576 10.9047 7.44346 10.5757 7.78271 10.5757Z"
          fill="#666666"
        />
      </svg>
    </span>
  );
}

export default QuestionIcon;

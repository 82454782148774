import React from 'react';
import UpButton from '../../components/Common/UpButton';
import QuestionIcon from '../../assets/icons/QuestionIcon';
import AnalyticsService from '../../services/analytics';

function HelpText({ prefilled, screen }) {
  function getPrefilledLink() {
    const loginCred = prefilled?.loginCred || '';
    const companyName = prefilled?.companyName || '';

    return `https://docs.google.com/forms/d/e/1FAIpQLSfA1sLkRmODKBjjvk3xFysuU6z9MUlnf-K5eymgfMDHEX2PaA/viewform?usp=pp_url&entry.1433034111=${loginCred}&entry.24550726=${companyName}`;
  }

  function onGetHelpClick() {
    // AnalyticsService.onHelpSupportLink('pre-login', screen);

    window.open(getPrefilledLink(), '_blank');
  }

  return (
    <div className="text-sm flex justify-center items-center mt-12 md:mt-2">
      <div className="text-up-clr-dark-grey mr-1 flex items-center">
        <QuestionIcon />
        <span className="ml-2">Can&apos;t access your account?</span>
      </div>
      <UpButton variant="link" onClick={onGetHelpClick}>
        Get Help
      </UpButton>
    </div>
  );
}

export default HelpText;

import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingCard from '../../components/LoadingCard';
import { verifyInviteShortLink } from '../../api/auth';
import UpToast from '../../components/Common/UpToast';
import { checkErrorTypeString } from '../../helpers/utils';

function VerifyInvite() {
  const navigate = useNavigate();
  const { inviteId } = useParams();

  const { mutate: callVerifyInviteShortLink } = useMutation({
    mutationFn: ({ params }) => verifyInviteShortLink(params),
    onSuccess: resp => {
      if (resp?.redirect_url) {
        window.open(resp?.redirect_url, '_self');
      } else {
        navigate('/logout');
      }
    },
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
      navigate('/logout');
    },
  });

  useEffect(() => {
    callVerifyInviteShortLink({
      params: { short_link_id: inviteId },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingCard />;
}

export default VerifyInvite;

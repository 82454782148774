import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardHeader from '../../components/CardHeader';
import UpButton from '../../components/Common/UpButton';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import PlaceholderBiz from '../../assets/images/placeholder-biz.svg';
import { useMutation } from '@tanstack/react-query';
import { acceptInvite, verifyInvite } from '../../api/auth';
import UpPopover from '../../components/Common/UpPopover';
import LinkedAccounts from '../../components/LinkedAccounts';
import { getRedirectRoute } from '../../helpers/bizDataHelpers';
import { checkErrorTypeString } from '../../helpers/utils';
import UpToast from '../../components/Common/UpToast';

function AcceptInvite() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteData = LocalStorageHelper.getInviteData();
  const allBizData = LocalStorageHelper.getBizData();

  const { isLoading: verifyLoading, mutate: callVerifyInvite } = useMutation({
    mutationFn: ({ params }) => verifyInvite(params),
    onSuccess: onInviteSuccess,
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
      navigate('/logout');
    },
  });

  useEffect(() => {
    if (searchParams.get('token')) {
      callVerifyInvite({
        params: {
          uuid: searchParams.get('token'),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, mutate: callAcceptInvite } = useMutation({
    mutationFn: ({ params }) => acceptInvite(params),
    onSuccess: onInviteSuccess,
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
    },
  });

  function onAcceptInviteClick() {
    if (inviteData?.details?.invitedThrough) {
      navigate('/invite/register');
      return;
    }

    callAcceptInvite({
      params: { user_data: {} },
    });
  }

  function onInviteSuccess(resp) {
    const redirect = getRedirectRoute(resp);

    if (redirect) {
      navigate(redirect);
    }
  }

  return (
    <div className="flex items-center justify-center flex-col flex-grow w-full pt-10 px-4">
      <div className="flex flex-col flex-grow">
        <UpLoadingBar loading={isLoading || verifyLoading} />
        <div
          style={{ marginBottom: 0 }}
          className="light-view-wrapper relative flex-grow">
          <UpLoadingCover loading={isLoading || verifyLoading} />
          <div>
            <CardHeader
              title={`Accept Invitation to ${
                inviteData?.details?.bizCount > 1
                  ? `${inviteData?.details?.bizCount} New Roles`
                  : 'a New Role'
              }`}
            />
            <div className="text-sm text-up-clr-dark-grey mt-3 mb-3">
              You have been invited{' '}
              {inviteData?.details?.invitedBy && (
                <span>
                  by{' '}
                  <span className="font-semibold">
                    {inviteData?.details?.invitedBy}
                  </span>
                </span>
              )}{' '}
              to join the following businesses.
            </div>
            {!inviteData?.details?.invitedThrough && !!allBizData?.length && (
              <UpPopover
                arrowAlign="start"
                contentSide="right"
                content={<LinkedAccounts />}>
                <UpButton variant="link" wrapperClass="w-max -ml-2 text-xs">
                  View already linked businesses
                </UpButton>
              </UpPopover>
            )}
            <div
              className="overflow-y-auto pb-24 mt-6"
              style={{ maxHeight: 'calc(100vh - 360px)' }}>
              {inviteData?.invites?.map((invite, lIdx) => {
                if (invite?.accounts?.length === 0) {
                  return null;
                }

                return (
                  <div key={invite?.platform}>
                    <p
                      className={classNames(
                        'text-base sm:text-lg font-semibold text-up-clr-black sm:px-5',
                        {
                          'border-t border-up-clr-neutral-grey pt-10': lIdx > 0,
                        },
                      )}>
                      {invite?.platform}
                    </p>
                    <div>
                      {invite?.accounts?.map((account, idx) => {
                        return (
                          <div
                            key={account?.app_biz_id}
                            className={classNames(
                              'sm:px-5 py-5 flex items-center',
                              {
                                'border-b border-up-clr-neutral-grey':
                                  idx < invite?.accounts?.length - 1,
                              },
                            )}>
                            <div className="w-12 h-12 flex-shrink-0 self-start mr-4">
                              <img
                                src={account?.app_biz_logo || PlaceholderBiz}
                                alt={account?.app_biz_name}
                                className="w-full h-full object-contain"
                              />
                            </div>
                            <div>
                              <p className="text-base sm:text-lg font-semibold text-up-clr-black">
                                {account?.app_biz_name}
                              </p>
                              <p className="text-sm text-up-clr-dark-grey">
                                {account?.roles?.join(', ')}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="py-4 px-6 absolute bottom-0 left-0 w-full bg-white box-shadow">
            <UpButton onClick={onAcceptInviteClick}>Accept Invite</UpButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptInvite;

import * as Sentry from '@sentry/react';
import AnalyticsService from './services/analytics';
import LocalStorageHelper from './helpers/localStorageHelpers';

/**
 * User has been identified
 */
function onUserIdentified() {
  const userDetails = LocalStorageHelper.getUserDetails();
  Sentry.setUser({ username: userDetails?.emailMobile });
  // AnalyticsService.identifyMixpanelUser(userDetails);
  AnalyticsService.identifyPostHogUser(userDetails);
}

/**
 * User has been cleared
 */
function onUserUnidentified() {
  Sentry.setUser(null);
  // AnalyticsService.removeMixpanelUser();
  AnalyticsService.removePostHogUser();
}

const Vent = {
  onUserIdentified,
  onUserUnidentified,
};

export default Vent;

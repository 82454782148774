import React from 'react';

function BrandTypeIcon({ multi = false, color = "#D0D0D0", ...props }) {
  if (multi) {
    return (
      <span className="multi-brand-icon">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path d="M0.583333 0H5.83333C6.15563 0 6.41667 0.261042 6.41667 0.583333V5.83333C6.41667 6.15563 6.15563 6.41667 5.83333 6.41667H0.583333C0.261042 6.41667 0 6.15563 0 5.83333V0.583333C0 0.261042 0.261042 0 0.583333 0ZM0.583333 7.58333H5.83333C6.15563 7.58333 6.41667 7.84438 6.41667 8.16667V13.4167C6.41667 13.739 6.15563 14 5.83333 14H0.583333C0.261042 14 0 13.739 0 13.4167V8.16667C0 7.84438 0.261042 7.58333 0.583333 7.58333ZM8.16667 0H13.4167C13.739 0 14 0.261042 14 0.583333V5.83333C14 6.15563 13.739 6.41667 13.4167 6.41667H8.16667C7.84438 6.41667 7.58333 6.15563 7.58333 5.83333V0.583333C7.58333 0.261042 7.84438 0 8.16667 0ZM8.16667 7.58333H13.4167C13.739 7.58333 14 7.84438 14 8.16667V13.4167C14 13.739 13.739 14 13.4167 14H8.16667C7.84438 14 7.58333 13.739 7.58333 13.4167V8.16667C7.58333 7.84438 7.84438 7.58333 8.16667 7.58333Z" fill={color}/>
        </svg>
      </span>
    );
  }
  return (
    <span className="single-brand-icon">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="14" height="14" rx="2" fill={color}/>
      </svg>
    </span>
  );
}

export default BrandTypeIcon;

import { isValidPhoneNumber } from 'libphonenumber-js';

/**
 * Check if text is valid phone number or email
 */
export function isValidPhoneNumberOrEmail(text, countryCode = '') {
  const emailRegex = /^\S+@\S+\.\S+$/;
  const isValidEmail = emailRegex.test(text);
  const isValidPhone = isValidPhoneNumber(`${countryCode}${text}`);

  return isValidEmail || isValidPhone;
}

/**
 * Check if error message type as string
 */
export function checkErrorTypeString(message, fallback = 'Error') {
  if (message && typeof message === 'string') {
    return message;
  }

  return fallback;
}

/**
 * Check if entered password is valid
 */
export function isValidPassword(password) {
  if (!password) {
    return false;
  }

  const lengthCheck = password.length >= 8;
  const numberCheck = /\d/.test(password);
  const specialCharCheck = /[^A-Za-z0-9]/.test(password);

  if (lengthCheck && numberCheck && specialCharCheck) {
    return true;
  }

  return false;
}

import React from 'react';

function FeaturedApps(props) {
  return (
    <span>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M18.318 3.44587C14.2223 3.40199 5.65766 3.38062 5.65766 3.38062L0.112915 9.77211L11.9982 22.3264L23.9565 9.77736C23.9565 9.77699 20.0029 5.30812 18.318 3.44587ZM16.155 7.93124L13.4569 4.84424C14.7274 4.83374 15.9975 4.82287 17.271 4.80787L16.155 7.93124ZM14.7225 8.90624C13.875 8.91749 13.0313 8.92874 12.1875 8.94749L8.89129 9.01124L11.9775 5.15249L14.7225 8.90624ZM18.7587 6.20886C19.4348 7.09161 20.1113 7.96649 20.7848 8.83237L19.92 8.84249L17.8088 8.86874L18.7587 6.20886ZM6.34316 4.87911C7.76554 4.87837 9.18716 4.87162 10.6088 4.86411L7.69129 8.50874L6.64129 5.77499L5.76379 6.98249L6.56254 9.05999L4.20379 9.12374L3.75004 9.74999L4.16629 10.3725L7.03129 10.4512L12.1875 10.5525C13.905 10.59 15.6263 10.6012 17.3438 10.6237L19.92 10.6575L20.5898 10.6654L14.6565 17.1971L17.0663 11.5912L15.0525 11.5237L11.9663 18.705L8.50504 11.2987L7.14754 11.25L9.69454 17.7112L2.13679 9.72786L6.34316 4.87911Z" fill="#646464"/>
      </svg>
    </span>
  );
}

export default FeaturedApps;

import React, { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import MarketingPosts from '../../components/MarketingPosts';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import TopBar from '../../components/TopBar';
import './style.scss';

function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (LocalStorageHelper.getAccessToken() && !searchParams.get('token')) {
      navigate('/business');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <TopBar showLogout={false} />
      <div className="login-wrapper flex-1">
        <div className="max-container flex items-center flex-grow w-full">
          <div className="post-wrapper">
            <MarketingPosts />
          </div>
          <div className="outlet-wrapper">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

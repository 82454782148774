import React from "react";
import classNames from "classnames";
import BrandTypeIcon from "../../assets/icons/BrandTypeIcon";
import "./style.scss"

const BusinessType = ({
  label = "Bussiness Type",
  value = false,
  loading = false,
  onChange,
  wrapperClass = ""
}) => {
  function onInputChange(val) {
    if (!onChange || loading) {
      return;
    }
    
    onChange(val);
  }

  function getColor(multi = false) {
    if (multi) {
      return "#3B5FEB";
    }
    return "#D0D0D0";
  }

  return (
    <div className={classNames("business-type", wrapperClass)}>
      <label>{label}</label>
      <div className="flex gap-4">
        <div className={classNames("type", !value ? "selected" : "")} onClick={() => onInputChange(false)}>
          <BrandTypeIcon color={getColor(!value)} />
          <div className="name">Single Brand</div>
        </div>
        <div className={classNames("type", value ? "selected" : "")} onClick={() => onInputChange(true)}>
          <BrandTypeIcon multi color={getColor(value)} />
          <div className="name">Multi-brand</div>
        </div>
      </div>
    </div>
  )
}

export default BusinessType
import React from 'react';

function UserIcon(props) {
  return (
    <span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          d="M10 1.25C6 1.25 4.5 2.25 4.5 5V5.625V6.25V6.50586C4.398 6.66661 4.25 6.96975 4.25 7.375C4.25 7.99525 4.62591 8.42425 4.80566 8.59375C5.00466 9.1105 5.35157 9.61204 5.73682 10.0503L5.62646 11.6533L1.25 13L1.36084 13.6646C1.44109 14.1466 1.85842 14.5 2.34717 14.5H13.6528C14.1416 14.5 14.5587 14.1466 14.6392 13.6646L14.75 13L10.3735 11.6533L10.2632 10.0503C10.6484 9.61179 10.9953 9.1105 11.1943 8.59375C11.3741 8.42425 11.75 7.99525 11.75 7.375C11.75 6.96975 11.602 6.66661 11.5 6.50586V6.5V5.625V5C11.5 4.125 11.25 2.75 10.5 2.75L10 1.25ZM5.75 4C5.75 4 6.875 4.25 8 4.25C9.125 4.25 10 4 10 4C10.6058 4.51924 10.7295 5.62609 10.75 6.26514V6.625L10.7515 6.67383C10.7508 6.68697 10.75 6.75 10.75 6.75L10.7534 6.74902L10.7539 6.7627L10.8374 6.86621C10.8442 6.87446 11 7.06925 11 7.375C11 7.79475 10.6497 8.07517 10.6484 8.07617L10.5581 8.14453L10.5205 8.25195C10.0813 9.5137 8.62813 10.5802 8.30713 10.75H7.69238C7.37138 10.5802 5.91825 9.5137 5.479 8.25195L5.44189 8.14453L5.35156 8.07617C5.34831 8.07342 5 7.79775 5 7.375C5 7.06925 5.15593 6.87437 5.15918 6.87012L5.25 6.76465V6.75V5.9126C5.277 5.24521 5.38311 4.36689 5.75 4ZM6.44043 10.752C6.96418 11.2092 7.4445 11.5 7.625 11.5H8.375C8.5555 11.5 9.03582 11.2092 9.55957 10.752L9.70459 12.8569C9.44446 13.0036 8.88086 13.25 8 13.25C7.11914 13.25 6.55554 13.0036 6.29541 12.8569L6.44043 10.752Z"
          fill="#222222"
        />
      </svg>
    </span>
  );
}

export default UserIcon;
